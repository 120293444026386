import { styled } from '@mui/material';
import { BUTTON_BORDER, IRON, MARINER, WHITE, WORKBUZZ_BLUE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

export const DepartmentWrapper = styled('div')({
  width: 'inherit',
  margin: '20px 0',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ButtonsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DownloadButton = styled('div')(
  {
    ...theme.typography.subtitle2,
    margin: '0 8px',
    padding: '9px 20px',
    width: 170,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    color: WHITE,
    background: WORKBUZZ_BLUE,
    fontWeight: 800,
    fontSize: 14,
    ':hover': {
      cursor: 'pointer',
    },
  },
  ({ isFeedback }: { isFeedback?: boolean }) => ({
    ...(isFeedback
      ? {}
      : {
          // Comment report button
          background: WHITE,
          color: MARINER,
          border: `2px solid ${IRON}`,
        }),
  })
);

export const DisabledButton = styled('span')({
  margin: '0 8px',
  padding: '9px 20px',
  width: 170,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  color: WHITE,
  backgroundColor: BUTTON_BORDER,
  fontWeight: 600,
  fontSize: 14,
  ':hover': {
    cursor: 'not-allowed',
  },
});
