import { styled } from '@mui/material';
import { ASH_IN_THE_AIR, BUTTON_BORDER, BUTTON_SHADOW, IRON, WHITE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

export type DropdownListProps = {
  visible: boolean;
};

export type DropdownItemProps = {
  alignedRight?: boolean;
};

export const DropdownWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '160px',
});

export const DropdownMenu = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  float: 'left',
  backgroundColor: WHITE,
  height: '38px',
  cursor: 'pointer',
  border: `2px solid ${IRON}`,
  boxSizing: 'border-box',
  borderRadius: '6px',
  padding: '0 15px 0 15px',
});

export const DropdownMenuLabel = styled('span')({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  width: '100%',
  position: 'relative',
  boxSizing: 'border-box',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
});

export const DropdownList = styled('div', {
  shouldForwardProp: (prop) => prop !== 'visible',
})(
  {
    float: 'left',
    position: 'relative',
    width: '100%',
    marginTop: '5px',
    zIndex: 200,
  },
  (props: DropdownListProps) => ({
    transform: props.visible ? 'scale(1, 1)' : 'scale(1, 0)',
  })
);

export const DropdownListItem = styled('div')({
  position: 'absolute',
  width: '100%',
  zIndex: 100,
  cursor: 'pointer',
  backgroundColor: WHITE,
  boxSizing: 'border-box',
  borderRadius: '5px',
  border: `1px solid ${BUTTON_BORDER}`,
  boxShadow: `0px 0px 11px 0px ${BUTTON_SHADOW}`,
});

export const DropdownItem = styled('div')(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    textAlign: 'center',
    width: '100%',
    height: '50px',
    padding: '0 16px',
    fontSize: '14px',
    boxSizing: 'border-box',
    '&:not(:first-child)': {
      borderTop: `1px solid ${BUTTON_BORDER}`,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ASH_IN_THE_AIR,
    },
  },
  (props: DropdownItemProps) => ({
    [theme.breakpoints.up('lg')]: {
      justifyContent: props.alignedRight ? 'right' : 'center',
    },
  })
);
