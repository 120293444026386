import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { store } from 'reduxStore/appStore';
import { theme } from 'app/theme/theme';
import { AppRoutes } from 'router/AppRoutes';
import { useInitializeGTM } from 'shared/hooks/useInitializeGTM';
import history from 'app/history';
import { WBGrowthBookProvider } from 'component/WBGrowthBookProvider/WBGrowthBookProvider';
import { ErrorBoundary } from 'component/ErrorBoundary/ErrorBoundary';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useRedirectToLogin } from 'shared/hooks/useRedirectToLogin';
import '@workbuzz/shared-ui/dist/esm/src/assets/plyr.css';

export const App = () => {
  useInitializeGTM();
  useRedirectToLogin();

  return (
    <ErrorBoundary>
      <PostHogProvider client={posthog}>
        <StoreProvider store={store}>
          <WBGrowthBookProvider>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <ConnectedRouter history={history}>
                  <AppRoutes />
                </ConnectedRouter>
              </ThemeProvider>
            </StyledEngineProvider>
          </WBGrowthBookProvider>
        </StoreProvider>
      </PostHogProvider>
    </ErrorBoundary>
  );
};
