import { useCallback } from 'react';

import { AIDialogHistoryItemModel } from 'api/dto/AIDialogHistory.dto';
import { useAISummaryDialogHistoryAtom } from 'shared/hooks/useAtoms';

export const useAIDialogHistory = () => {
  const [history, setHistory] = useAISummaryDialogHistoryAtom();

  const addHistoryItem = useCallback(
    (newItem: AIDialogHistoryItemModel) => {
      setHistory((prevHistory) => (prevHistory ? [...prevHistory, newItem] : [newItem]));
    },
    [setHistory]
  );

  const removeHistory = useCallback(() => {
    setHistory(null);
  }, [setHistory]);

  return { history, addHistoryItem, removeHistory };
};
