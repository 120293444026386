import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TagCloud from 'react-tag-cloud';

import { selectWordCloud, selectWordCloudResource } from 'reduxStore/wordCloud/selectors';
import { MARINER, WHITE } from 'app/theme/colors';
import { fetchCommentAnswers } from 'reduxStore/comments/asyncActions';
import { scaleLinear } from 'd3-scale';
import { max, min } from 'd3';
import { useAppDispatch } from 'reduxStore/appStore';
import { resetPaginationPage, setSearchText } from 'reduxStore/comments/slice';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Resource } from 'model/Resource';
import { WordCloud } from 'model/WordCloud';
import { Box, Typography } from '@mui/material';
import { theme } from 'app/theme/theme';

export type CommentWordCloudPureProps = {
  wordCloudResource: Resource<WordCloud>;
  wordCloud: WordCloud;
  onWordClick: (word: string) => void;
};

const CommentWordCloudPure: React.FC<CommentWordCloudPureProps> = ({
  wordCloudResource,
  wordCloud,
  onWordClick,
}) => {
  const maxWordValueSize = useMemo(() => max(wordCloud, (word) => word.value), [wordCloud]);
  const minWordValueSize = useMemo(() => min(wordCloud, (word) => word.value), [wordCloud]);
  const maxFontSize = 30;
  const minFontSize = 10;

  const fontScale = scaleLinear()
    .domain([minWordValueSize || 0, maxWordValueSize || 1])
    .range([minFontSize, maxFontSize]);

  return (
    <Box data-testid="wordCloud">
      <ResourceLoader resource={wordCloudResource}>
        <>
          <Typography variant="h5">Frequently used words</Typography>
          <TagCloud
            random={() => 1}
            style={{
              fontWeight: 'bold',
              fontFamily: theme.typography.fontFamily,
              backgroundColor: WHITE,
              padding: 8,
              width: '100%',
              height: 150,
            }}
          >
            {wordCloud.map((word, index) => (
              <Typography
                component="span"
                data-testid={`singleWordCloud[${word.value}]`}
                key={index}
                style={{
                  fontSize: fontScale(word.value),
                  color: MARINER,
                  cursor: 'pointer',
                  borderRadius: 8,
                  padding: 8,
                }}
                onClick={() => onWordClick(word.name)}
              >
                {word.name}
              </Typography>
            ))}
          </TagCloud>
        </>
      </ResourceLoader>
    </Box>
  );
};

export const CommentWordCloud: React.FC = () => {
  const dispatch = useAppDispatch();

  const wordCloudResource = useSelector(selectWordCloudResource);
  const wordCloud = useSelector(selectWordCloud);

  const onWordClick = (word: string) => {
    dispatch(setSearchText(word));
    dispatch(fetchCommentAnswers());
    dispatch(resetPaginationPage());
  };

  return (
    <CommentWordCloudPure
      wordCloudResource={wordCloudResource}
      wordCloud={wordCloud}
      onWordClick={onWordClick}
    />
  );
};
