import { ReportAvailability } from 'model/ReportAvailability';
import { Pagination as PaginationModel } from 'model/Pagination';
import { QuestionsStats as QuestionStatsType } from 'model/Question';
import { SentimentState } from 'model/Sentiment';
import { ProjectModel } from 'model/Project.model';
import { ProjectListItemModel } from 'model/ProjectListItem.model';
import { Resource } from 'model/Resource';
import { ProjectLiveSettingsModel } from 'api/dto/ProjectLiveSettingsDto';

export const STORE_NAME = 'project';

export namespace ProjectState {
  export type Domain = {
    current: Resource<ProjectModel>;
    currentTemplateLastPulseId: Resource<ProjectModel['id'] | null>;
    list: Resource<ProjectListItemModel[]>;
    pagination: PaginationModel | null;
    questionsStats: Resource<QuestionStatsType | null>;
    reportAvailabilities: Resource<ReportAvailability[]>;
    sentiment: Resource<SentimentState>;
    liveSettings: Resource<ProjectLiveSettingsModel | null>;
  };

  export const INITIAL_DOMAIN: ProjectState.Domain = {
    current: Resource.createPendingResource({} as ProjectModel),
    currentTemplateLastPulseId: Resource.createPendingResource(null),
    list: Resource.createPendingResource([]),
    pagination: null,
    questionsStats: Resource.createPendingResource(null),
    reportAvailabilities: Resource.createPendingResource([]),
    sentiment: Resource.createPendingResource(SentimentState.Off),
    liveSettings: Resource.createPendingResource(null),
  };

  export const DEFAULT_AGGREGATION_MONTHS = 12;
}

export type ProjectState = {
  project: ProjectState.Domain;
};
