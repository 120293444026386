import { styled } from '@mui/material';
import { theme } from 'app/theme/theme';

export type ClassificationProps = {
  checked: boolean;
};

export const DemographyFilterChildWrapper = styled('div')({
  width: '100%',
});

export const DemographyChild = styled('li')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  ':hover': {
    cursor: 'pointer',
  },
});

export const Classification = styled('span')({
  ...theme.typography.body1,
  width: 197,
  lineHeight: '30px',
});
