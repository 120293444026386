import React from 'react';
import { push } from 'connected-react-router';
import { useSelector } from 'react-redux';

import {
  AIDialogEmptyContentsInfo,
  AIDialogEmptyContentsWrapper,
} from 'component/AIDialogEmptyContents/AIDialogEmptyContents.s';
import { SxProps, Typography } from '@mui/material';
import { WORKBUZZ_BLUE } from 'app/theme/colors';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { compilePath } from 'router/compilePath';
import { AppRoute } from 'app/route/app';
import { WBButton, WBFallbackImage } from '@workbuzz/shared-ui';

export const AIDialogEmptyContents: React.FC<{ sx?: SxProps }> = ({ sx }) => {
  const dispatch = useAppDispatch();
  const currentProjectId = useSelector(selectCurrentProjectId);

  const redirectToSummary = () => {
    const href = compilePath(AppRoute.Home.SUMMARY, { id: currentProjectId });
    dispatch(push(href));
  };

  return (
    <AIDialogEmptyContentsWrapper sx={sx}>
      <WBFallbackImage
        src="/assets/ai_logo.svg"
        alt="AI logo"
        width={30}
        height={30}
        aria-hidden="true"
      />
      <Typography variant="h4" sx={{ color: WORKBUZZ_BLUE }}>
        Get started
      </Typography>
      <AIDialogEmptyContentsInfo>
        <Typography variant="body1">
          WorkBuzz’s People Science AI is currently available from the home page, where you can see
          a summary of your data at overall company level and individual department level.
        </Typography>
      </AIDialogEmptyContentsInfo>
      <WBButton
        data-testid="aiDialogEmptyContentsButton"
        color="primary"
        onClick={redirectToSummary}
        size="medium"
        variant="text"
        sx={{ mt: 1 }}
      >
        Clear filters and visit home
      </WBButton>
    </AIDialogEmptyContentsWrapper>
  );
};
