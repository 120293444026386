import React from 'react';
import { useSelector } from 'react-redux';

import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import {
  downloadAllFeedbackPdfReport,
  downloadFeedbackReport,
} from 'reduxStore/report/asyncActions';
import { DocumentType } from 'model/DocumentType';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { useAppDispatch } from 'reduxStore/appStore';
import {
  selectReportDepartment,
  selectSelectedDepartmentIds,
} from 'reduxStore/departments/selectors';
import { selectGroupedClassifications } from 'reduxStore/demography/selectors';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import {
  getReportActionNameByDepartmentId,
  getReportActionNameByReportFormat,
} from 'reduxStore/report/getReportActionName';
import { selectShouldShowDownloadButton } from 'reduxStore/filtering/selectors';
import { useIsVariableConfidentialityCompareAtom } from 'shared/hooks/useAtoms';

export type PageHeaderProps = {
  title?: string | null;
  isDownloadDisabled?: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ title, isDownloadDisabled }) => {
  const dispatch = useAppDispatch();
  const [isVariableConfidentialityCompare] = useIsVariableConfidentialityCompareAtom();

  const reportDepartment = useSelector(selectReportDepartment);
  const selectedDepartmentIds = useSelector(selectSelectedDepartmentIds);
  const selectedClassificationIds = useSelector(selectGroupedClassifications);

  // Download "Opinion & Insights - PDF" dropdown item if 0 or 1 department is selected
  const isVisibleDownloadAllFeedbackItem =
    selectedDepartmentIds.length <= 1 && Object.keys(selectedClassificationIds).length < 1;

  const isExcelDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadFeedbackReport, { type: ReportFormat.ExcelSummary })
    )
  );
  const isPdfDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadFeedbackReport, { type: ReportFormat.PDF })
    )
  );
  const isPowerPointDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByReportFormat(downloadFeedbackReport, { type: ReportFormat.PowerPoint })
    )
  );
  const isAllFeedbackPdfReportDownloadPending = useSelector(
    selectIsPendingReport(
      getReportActionNameByDepartmentId(downloadAllFeedbackPdfReport, {
        departmentId: reportDepartment?.id,
      })
    )
  );

  const onReportDownload = (type: ReportFormatType) => dispatch(downloadFeedbackReport({ type }));

  const onAllFeedbackPdfReportDownload = () =>
    dispatch(
      downloadAllFeedbackPdfReport({
        departmentId: reportDepartment?.id,
        departmentName: reportDepartment?.name,
      })
    );

  const shouldShowPDFandPPT = useSelector(selectShouldShowDownloadButton);

  return (
    <PageWrapperHeader isTitleVisible={Boolean(title)} data-testid="summaryPageHeader">
      {title && <PageTitle title={title} data-testid="summaryPageTitle" />}
      {!isDownloadDisabled && !isVariableConfidentialityCompare && (
        <Dropdown label="Download Report" data-testid="downloadReportDropdown">
          <DropdownItem
            data-testid="downloadReportExcel"
            onClick={() => onReportDownload(ReportFormat.ExcelSummary)}
          >
            <DocumentIcon type={DocumentType.Excel} isDownloadPending={isExcelDownloadPending} />
            Excel
          </DropdownItem>
          {shouldShowPDFandPPT && (
            <>
              <DropdownItem
                data-testid="downloadReportPDF"
                onClick={() => onReportDownload(ReportFormat.PDF)}
              >
                <DocumentIcon type={DocumentType.PDF} isDownloadPending={isPdfDownloadPending} />
                PDF
              </DropdownItem>
              <DropdownItem
                data-testid="downloadReportPowerpoint"
                onClick={() => onReportDownload(ReportFormat.PowerPoint)}
              >
                <DocumentIcon
                  type={DocumentType.PowerPoint}
                  isDownloadPending={isPowerPointDownloadPending}
                />
                PowerPoint
              </DropdownItem>
            </>
          )}

          {shouldShowPDFandPPT && isVisibleDownloadAllFeedbackItem && (
            <DropdownItem
              data-testid="downloadAllFeedbackPDFReport"
              onClick={onAllFeedbackPdfReportDownload}
            >
              <DocumentIcon
                type={DocumentType.PDF}
                isDownloadPending={isAllFeedbackPdfReportDownloadPending}
              />
              Combined with Insights - PDF
            </DropdownItem>
          )}
        </Dropdown>
      )}
    </PageWrapperHeader>
  );
};
