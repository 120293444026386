import { createTheme } from '@mui/material';
import { BLACK, PAGE_BACKGROUND, WHITE } from 'app/theme/colors';
import { deepmerge } from '@mui/utils';
import { wbTheme } from '@workbuzz/shared-ui';

const themeOverrides = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        body: {
          fontFamily: wbTheme.typography.fontFamily,
          backgroundColor: PAGE_BACKGROUND,
          '@media print': {
            backgroundColor: WHITE,
          },
        },
      }),
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...wbTheme.typography.subtitle2,
          backgroundColor: BLACK,
          borderRadius: '4px',
          padding: '10px 16px',
          maxWidth: 500,
          color: `${WHITE} !important`,
          textAlign: 'center',
        },
        popper: {
          ...wbTheme.typography.subtitle2,
          color: WHITE,
        },
        arrow: {
          color: BLACK,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
  },
});

export const theme = createTheme(deepmerge(themeOverrides, wbTheme));
