import { AppState } from 'reduxStore/appStore';
import { createSelector } from '@reduxjs/toolkit';
import { AIState } from 'reduxStore/ai/initialState';

export const selectDomain = (state: AppState): AIState.Domain => {
  return state.ai;
};

export const selectAIAvatarResource = createSelector(selectDomain, (domain) => domain.avatar);

export const selectAIAvatar = createSelector(
  selectAIAvatarResource,
  (aiAvatarResource) => aiAvatarResource.data
);

export const selectAISummaryResource = createSelector(selectDomain, (domain) => domain.summary);

export const selectAISummary = createSelector(
  selectAISummaryResource,
  (aiSummaryResource) => aiSummaryResource.data
);
