import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFetchGrowthbook } from 'shared/hooks/useFetchGrowthbook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { growthbook } from 'config/growthbook';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';

type WBGrowthBookProviderProps = {
  children: React.JSX.Element;
};

export const WBGrowthBookProvider: React.FC<WBGrowthBookProviderProps> = ({ children }) => {
  const { loading } = useFetchGrowthbook();
  const surveyId = useSelector(selectCurrentProjectId)?.toString();

  useEffect(() => {
    growthbook.setAttributes({ surveyId });
  }, [surveyId]);

  return (
    <>{!loading && <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>}</>
  );
};
