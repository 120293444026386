import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectLifecycleFiltersQuery } from 'util/selector/querySelector';

import {
  Line,
  LineChart,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { ResponseCountModel } from 'api/dto/ResponseCount.dto';
import { aggregatedResponseCountApi } from 'container/aggregatedResponseCountApi';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { ResponsesChartTooltip } from 'component/LifecycleCharts/ResponsesChart/ResponsesChartTooltip';
import { IRON, MARINER, SONIC_SILVER } from 'app/theme/colors';
import { useDebounceValue } from 'shared/hooks/useDebounceValue';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { Typography } from '@mui/material';
import { theme } from 'app/theme/theme';

import { ChartWrapper } from '../LifecycleCharts.s';

export const ResponsesChart: React.FC = () => {
  const projectId = useSelector(selectCurrentProjectId);
  const query = useSelector(selectLifecycleFiltersQuery);
  const debouncedQuery = useDebounceValue(query, FILTERS_CHANGED_DEBOUNCE_TIMEOUT);
  const [responsesCounts, setResponsesCounts] = useState<ResponseCountModel[]>([]);

  useEffect(() => {
    aggregatedResponseCountApi.list({ projectId, query: debouncedQuery }).then(setResponsesCounts);
  }, [projectId, debouncedQuery]);

  const defaultMaxValue = 1;
  const maxValue = Math.max(...responsesCounts.map(({ value }) => value)) || defaultMaxValue;

  return (
    <ChartWrapper>
      <Typography mb="20px" variant="h5" data-testid="responsesChartTitle">
        Number of responses
      </Typography>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={responsesCounts} id="responsesChart">
          <XAxis
            stroke={IRON}
            strokeWidth={3}
            style={{
              ...theme.typography.body2,
              fill: SONIC_SILVER,
            }}
            dy={5}
            dataKey="dateLabel"
          />
          <YAxis
            dataKey="value"
            stroke={IRON}
            strokeWidth={3}
            style={{
              ...theme.typography.body2,
              fill: SONIC_SILVER,
            }}
            dx={-5}
            allowDecimals={false}
            domain={[0, maxValue]}
            width={20}
          />
          <ChartTooltip content={<ResponsesChartTooltip />} />
          <Line
            type="linear"
            dataKey="value"
            stroke={MARINER}
            strokeWidth={4}
            animationDuration={50}
          />
        </LineChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};
