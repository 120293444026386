import { ActionReducerMapBuilder, AsyncThunk, Draft, PayloadAction } from '@reduxjs/toolkit';
import { Resource } from 'model/Resource';

export function addAsyncCases<TState, TPayload, TProps = any>(
  builder: ActionReducerMapBuilder<TState>,
  asyncActionCreator: AsyncThunk<TPayload, TProps, {}>,
  reducer: (
    state: Draft<TState>,
    action: PayloadAction<
      Resource<TPayload | null>,
      string,
      { arg: TProps; requestId: string; requestStatus: 'pending' | 'fulfilled' | 'rejected' }
    >
  ) => void,
  initialState?: Resource<TPayload>['data']
) {
  builder
    .addCase(asyncActionCreator.pending, (state, action) => {
      reducer(state, {
        ...action,
        payload: Resource.createPendingResource(initialState || null),
      });
    })
    .addCase(asyncActionCreator.fulfilled, (state, action) => {
      reducer(state, {
        ...action,
        payload: Resource.createResolvedResource({ data: action.payload }),
      });
    })
    .addCase(asyncActionCreator.rejected, (state, action) => {
      // Error is serialized by @redux-toolkit into a plain object
      reducer(state, {
        ...action,
        payload: Resource.createRejectedResource({ error: action.payload ?? action.error }),
      });
    });
}
