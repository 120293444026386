export enum AIAvatarStatusEnum {
  Completed = 'completed',
  Pending = 'pending',
}

export type AIAvatarItemDto = {
  status: AIAvatarStatusEnum;
  title: string;
  url: string | null;
};

export type AIAvatarsDto = {
  id: string;
  sections: AIAvatarItemDto[];
};

export type AIAvatarItemModel = AIAvatarItemDto;

export type AIAvatarsModel = {
  id: string;
  sections: AIAvatarItemModel[];
};

export const mapAIAvatarItemDtoToModel = (dto: AIAvatarItemDto): AIAvatarItemModel => ({
  status: dto.status,
  title: dto.title,
  url: dto.url,
});

export const mapAIAvatarsDtoToModel = (dto: AIAvatarsDto): AIAvatarsModel => ({
  id: dto.id,
  sections: dto.sections.map(mapAIAvatarItemDtoToModel),
});
