import React, { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectIsReportView } from 'util/selector/reportSelector';

import { PageHeader } from 'component/PageHeader/PageHeader';
import {
  InfoWrapper,
  PaginationWrapper,
  ProjectEndDate,
  ProjectList,
  ProjectListItem,
  ProjectName,
  ProjectWrapper,
  SelectProjectPageWrapper,
  SelectProjectTooltipWrapper,
} from 'view/SelectProjectPage/SelectProjectPage.s';
import {
  selectHasProjects,
  selectPagination,
  selectProjectList,
} from 'reduxStore/project/selectors';
import { Project } from 'model/Project';
import { fetchProjects } from 'reduxStore/project/asyncActions';
import { Tooltip, Typography } from '@mui/material';
import { ProjectKindLabel } from 'component/ProjectKindLabel/ProjectKindLabel';
import { ProjectKind } from 'model/ProjectKind';
import { Pagination } from 'component/Pagination/Pagination';
import { ProjectState } from 'reduxStore/project/initialState';
import { useAppDispatch } from 'reduxStore/appStore';
import { SimplifiedTopNavBar } from 'component/SimplifyTopNavBar/SimplifiedTopNavBar';
import { projectUrlBuilder } from 'shared/utils/builders/projectUrlBuilder';
import { ProjectListItemModel } from 'model/ProjectListItem.model';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { Resource } from 'model/Resource';
import { ErrorHandler } from 'component/ErrorHandler/ErrorHandler';
import { clearProject } from 'reduxStore/project/slice';
import { route } from 'reduxStore/router/asyncActions';
import { theme } from 'app/theme/theme';
import { WHITE } from 'app/theme/colors';

export type SelectProjectPageProps = {
  hasProject: boolean;
  projects: Resource<ProjectListItemModel[]>;
  pagination: ProjectState.Domain['pagination'];
  fetchProjects: (page: number) => void;
};

export const SelectProjectPagePure: React.FC<SelectProjectPageProps> = ({
  hasProject,
  projects,
  pagination,
  fetchProjects,
}) => {
  const renderProjectTitle = (project: ProjectListItemModel): ReactElement<any> => {
    const title = project.kind === ProjectKind.PULSE_TEMPLATE ? project.companyName : project.name;

    return <ProjectName translate="no"> {title} </ProjectName>;
  };

  const renderNoProjectInfo = (): ReactElement<any> => {
    return (
      <InfoWrapper>
        <Typography sx={{ ...theme.typography.h4, fontWeight: 400 }}>
          There are no surveys you have access to
        </Typography>
      </InfoWrapper>
    );
  };
  const renderProjectList = (): ReactElement<any> => {
    return (
      <SelectProjectPageWrapper>
        <ProjectList>
          {projects.data?.map((project, index) => (
            <ProjectWrapper key={index}>
              <Tooltip
                title={
                  <SelectProjectTooltipWrapper>
                    {project.publishedAt.format('MMMM Do YYYY, h:mm:ss a')}
                    <ProjectKindLabel kind={project.kind} color={WHITE} />
                  </SelectProjectTooltipWrapper>
                }
                data-tesid={`tooltipForProject${project.id.toString()}`}
              >
                <span style={{ maxWidth: 300 }} data-testid={`projectListItem${index}`}>
                  <ProjectListItem key={index} to={projectUrlBuilder.getDefault(project)}>
                    {renderProjectTitle(project)}
                    <ProjectEndDate>
                      ({Project.getMonthName(project.closedAt.month())} {project.closedAt.year()})
                    </ProjectEndDate>
                  </ProjectListItem>
                </span>
              </Tooltip>
            </ProjectWrapper>
          ))}
          {pagination && pagination.pageCount > 1 && (
            <PaginationWrapper>
              <Pagination
                pageCount={pagination.pageCount}
                page={pagination.index}
                onPageChange={(page) => fetchProjects(page)}
              />
            </PaginationWrapper>
          )}
        </ProjectList>
      </SelectProjectPageWrapper>
    );
  };

  return (
    <>
      <SimplifiedTopNavBar />
      <div>
        <PageHeader title="Welcome" isDownloadDisabled />
        <ResourceLoader resource={projects} rejected={(error) => <ErrorHandler error={error} />}>
          {hasProject ? renderProjectList() : renderNoProjectInfo()}
        </ResourceLoader>
      </div>
    </>
  );
};

export const SelectProjectPage = () => {
  const dispatch = useAppDispatch();
  const hasProject = useSelector(selectHasProjects);
  const projects = useSelector(selectProjectList);
  const pagination = useSelector(selectPagination);
  const isReportView = useSelector(selectIsReportView);

  useEffect(() => {
    dispatch(clearProject());
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    // redirect if only one project
    if (pagination?.pageCount === 1 && projects?.data.length === 1 && !isReportView) {
      const firstProject = projects.data[0];
      dispatch(route({ pattern: projectUrlBuilder.getDefault(firstProject) }));
    }
  }, [dispatch, isReportView, pagination, projects]);

  return (
    <SelectProjectPagePure
      hasProject={hasProject}
      projects={projects}
      pagination={pagination}
      fetchProjects={(page) => dispatch(fetchProjects(page))}
    />
  );
};
