import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router';

import { AppRoute } from 'app/route/app';
import { FiltersMenu } from 'component/FiltersMenu/FiltersMenu';
import { createRelativeRoute } from 'component/RelativeRoute/RelativeRoute';
import { selectIsSidebarHidden } from 'reduxStore/visualSettings/selectors';
import { Navigation } from 'component/Navigation/Navigation';
import { clearVisualSettings } from 'reduxStore/visualSettings/slice';
import { useProjectRoutes } from 'router/ProjectRoutes/useProjectRoutes';
import { renderRoutes } from 'router/components/renderRoutes';
import { NavigationWithoutProject } from 'component/NavigationWithoutProject/NavigationWithoutProject';
import { useGetUserPermissionsOnCurrentProjectChange } from 'router/hooks/useGetUserPermissionsOnCurrentProjectChange';
import { Drawer, useMediaQuery, useTheme } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useGetClearPageFiltersHandler } from 'shared/hooks/useGetClearPageFiltersHandler';
import { useFetchSentimentState } from 'shared/hooks/useFetchSentimentState';
import { useFetchCurrentProject } from 'shared/hooks/useFetchCurrentProject';
import { useExpandAllDepartments } from 'shared/hooks/useExpandAllDepartments';
import {
  selectCurrentProjectResource,
  selectIsCurrentProjectResolved,
  selectIsPulseProjectKind,
  selectProjectLiveSettings,
} from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { ResourceLoader } from 'component/ResourceLoader/ResourceLoader';
import { useClearSummaryOnUnmount } from 'shared/hooks/useClearSummaryOnUnmount';
import { useLocalize } from 'shared/hooks/useLocalize';
import { MARINER } from 'app/theme/colors';
import ScrollToTop from 'router/components/ScrollToTop';
import { AIDialog } from 'component/AIDialog/AIDialog';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useFetchProjectLiveSettings } from 'shared/hooks/useFetchProjectLiveSettings';

import {
  AppWrapper,
  ContentBodyWrapper,
  ContentWrapper,
  FiltersToggleButton,
  LeftMenuWrapper,
  MainWrapper,
} from './ProjectRoutes.s';

const RelativeRoute = createRelativeRoute(AppRoute.HOME);

export const ProjectRoutes: React.FC = () => {
  const dispatch = useAppDispatch();
  const routes = useProjectRoutes();
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const hideSidebar = useSelector(selectIsSidebarHidden);
  const currentProjectResource = useSelector(selectCurrentProjectResource);
  const isProjectRequestStatusResolved = useSelector(selectIsCurrentProjectResolved);
  const isPulseProject = useSelector(selectIsPulseProjectKind);
  const isAISummariesEnabledFeature = useFeatureIsOn('is-ai-summaries-enabled');
  const { hasCompanyAiSummariesEnabled, isExecutiveSummaryEnabled, isDepartmentSummaryEnabled } =
    useSelector(selectProjectLiveSettings) || {};

  const hasAIDialog =
    isAISummariesEnabledFeature &&
    isPulseProject &&
    hasCompanyAiSummariesEnabled &&
    (isExecutiveSummaryEnabled || isDepartmentSummaryEnabled);

  const clearPageFiltersHandler = useGetClearPageFiltersHandler();
  useFetchCurrentProject();
  useClearSummaryOnUnmount();
  useFetchSentimentState();
  useGetUserPermissionsOnCurrentProjectChange();
  useExpandAllDepartments();
  useFetchProjectLiveSettings();

  useLocalize(({ defaultLanguage, localize }) => {
    if (defaultLanguage) {
      localize.setLanguage(defaultLanguage);
    }
  });

  useEffect(() => {
    return () => {
      dispatch(clearVisualSettings());
    };
  }, [dispatch]);

  return (
    <AppWrapper>
      {hasAIDialog && <AIDialog />}
      <ScrollToTop />
      <ResourceLoader
        resource={currentProjectResource}
        rejected={() => <NavigationWithoutProject />}
        hideLoader
      >
        <Navigation />
      </ResourceLoader>
      <MainWrapper>
        {!hideSidebar &&
          (isDesktop ? (
            <LeftMenuWrapper data-testid="desktopFilters">
              <FiltersMenu onClearAllFilters={clearPageFiltersHandler} />
            </LeftMenuWrapper>
          ) : (
            <>
              {isProjectRequestStatusResolved && (
                <FiltersToggleButton
                  data-testid="mobileFiltersToggleButton"
                  variant="contained"
                  startIcon={<FilterListIcon sx={{ fill: MARINER }} />}
                  disableElevation={true}
                  onClick={() => setAreFiltersOpen(true)}
                  hidden
                >
                  Filters
                </FiltersToggleButton>
              )}
              <Drawer
                data-testid="mobileFilters"
                anchor={'left'}
                open={areFiltersOpen}
                onClose={() => setAreFiltersOpen(false)}
                sx={{
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                  },
                }}
              >
                <LeftMenuWrapper>
                  <FiltersMenu onClearAllFilters={clearPageFiltersHandler} />
                </LeftMenuWrapper>
              </Drawer>
            </>
          ))}
        <ContentWrapper>
          <ContentBodyWrapper>
            <Switch>{renderRoutes({ RelativeRoute, routes })}</Switch>
          </ContentBodyWrapper>
        </ContentWrapper>
      </MainWrapper>
    </AppWrapper>
  );
};
