import React from 'react';

import {
  ColumnWrapper,
  TableRow,
  TableTrendPlotColumn,
  TableTrendPlotWrapper,
} from 'view/EngagementDriverByCategoryPage/QuestionTable/Body/Body.s';
import { FeedbackBar } from 'component/FeedbackBar/FeedbackBar';
import { Statistics } from 'component/Statistics/Statistics';
import { Benchmark } from 'model/Benchmark';
import {
  QuestionDataElementType,
  QuestionDataType,
} from 'view/EngagementDriverByCategoryPage/QuestionTable/QuestionTable';
import { TableTrendPlot } from 'component/TableTrendPlot/TableTrendPlot';
import { plotDataToTableData } from 'model/Insight';
import { Tooltip } from '@mui/material';

import { QuestionColumn } from '../QuestionColumn/QuestionColumn';

export namespace Body {
  export type Props = {
    data: QuestionDataType;
    showImpactOnEngagement: boolean;
    shouldDisplayTrendPlotDataColumn: boolean;
    shouldRenderCategory?: boolean;
    isReportView?: boolean;
  };
}

export const Body = (props: Body.Props): React.JSX.Element => {
  const renderTrendCells = (trends: QuestionDataElementType['trends']) => {
    return trends?.map((trend) => (
      <Tooltip
        title={trend.askedAt && `Question previously asked in ${trend.askedAt!.format('MMM YYYY')}`}
        placement="top"
        enterTouchDelay={0}
        arrow
      >
        <ColumnWrapper>
          <Statistics score={trend.diff} isReportView={props.isReportView} />
        </ColumnWrapper>
      </Tooltip>
    ));
  };

  const renderBenchmarkCell = (
    benchmark: QuestionDataElementType['benchmark'],
    isReportView?: boolean
  ) => {
    return (
      Benchmark.isNotNone(benchmark) && (
        <ColumnWrapper>
          {benchmark && <Statistics score={benchmark.value} isReportView={isReportView} />}
        </ColumnWrapper>
      )
    );
  };

  const renderBenchmarkTrendCells = (
    opinionQuestionDetails: QuestionDataElementType,
    isReportView?: boolean
  ) => {
    return (
      <>
        {renderTrendCells(opinionQuestionDetails.trends)}
        {renderBenchmarkCell(opinionQuestionDetails.benchmark, isReportView)}
      </>
    );
  };

  const renderCategoryCell = (data: QuestionDataElementType) => {
    return props.shouldRenderCategory && data?.categoryTitle ? (
      <ColumnWrapper>{data.categoryTitle}</ColumnWrapper>
    ) : null;
  };

  return (
    <tbody data-testid="questionTableBody">
      {props.data.map((data, index) => {
        return (
          <TableRow key={index} data-testid={`questionTableRow[${index}]`}>
            {renderCategoryCell(data)}
            <QuestionColumn question={data.question} />
            {props.showImpactOnEngagement && (
              <ColumnWrapper data-testid="impactOfEngagementScore">
                {data.impactOnEngagement}
              </ColumnWrapper>
            )}
            <ColumnWrapper style={{ textAlign: 'center' }}>
              <FeedbackBar data={data.feedback} width={200} height={23} />
            </ColumnWrapper>
            <ColumnWrapper
              data-testid="dataScore"
              style={{ textAlign: 'center', paddingRight: props.isReportView ? 0 : '18px' }}
            >
              {data.score}
            </ColumnWrapper>
            {renderBenchmarkTrendCells(data, props.isReportView)}
            {props.shouldDisplayTrendPlotDataColumn && (
              <TableTrendPlotColumn>
                <TableTrendPlotWrapper>
                  <TableTrendPlot
                    data={data.question.trendPlotData?.map(plotDataToTableData) ?? []}
                    width={100}
                    height={50}
                  />
                </TableTrendPlotWrapper>
              </TableTrendPlotColumn>
            )}
          </TableRow>
        );
      })}
    </tbody>
  );
};
