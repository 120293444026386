import React from 'react';

import { scrollToLastElement } from 'util/scrollToLastElement';

import { WBGenAIButton, WBModal } from '@workbuzz/shared-ui';
import { AIDialogContents } from 'component/AIDialogContents/AIDialogContents';
import { AIDialogEmptyContents } from 'component/AIDialogEmptyContents/AIDialogEmptyContents';
import { useAIDialogHistory } from 'shared/hooks/useAIDialogHistory';
import { useClearAIDialogHistory } from 'shared/hooks/useClearAIDialogHistory';
import {
  useIsAISummaryDialogExpendedAtom,
  useIsAISummaryDialogOpenAtom,
} from 'shared/hooks/useAtoms';

export const AIDialog: React.FC = () => {
  const { history } = useAIDialogHistory();
  const [isDialogOpen, setIsDialogOpen] = useIsAISummaryDialogOpenAtom();
  const [isDialogExpended, setIsDialogExpended] = useIsAISummaryDialogExpendedAtom();

  useClearAIDialogHistory();

  const toggleDialogOpen = () => {
    setIsDialogOpen(!isDialogOpen);
    scrollToLastElement('.aiDialogQuestion', { behavior: 'auto', block: 'start' });
  };

  return (
    <>
      <WBModal
        isOpen={isDialogOpen}
        isInitiallyExpended={isDialogExpended}
        isExpended={isDialogExpended}
        setIsExpanded={setIsDialogExpended}
        handleToggleOpen={toggleDialogOpen}
        aiPolicyHref={'https://workbuzz.com/about/ai-security-privacy/'}
      >
        {history ? <AIDialogContents /> : <AIDialogEmptyContents />}
      </WBModal>
      {!isDialogOpen && <WBGenAIButton onClick={toggleDialogOpen} />}
    </>
  );
};
