import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { selectCurrentProjectId } from 'reduxStore/project/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { fetchProjectLiveSettings } from 'reduxStore/project/asyncActions';

export const useFetchProjectLiveSettings = () => {
  const dispatch = useAppDispatch();
  const projectId = useSelector(selectCurrentProjectId);
  // TODO: Remove the isAISummariesEnabled dependency if project settings are needed elsewhere
  const isAISummariesEnabled = useFeatureIsOn('is-ai-summaries-enabled');

  useEffect(() => {
    if (isAISummariesEnabled) {
      dispatch(fetchProjectLiveSettings(projectId));
    }
  }, [isAISummariesEnabled, projectId, dispatch]);
};
