import { CSSProperties } from 'react';

import { CARDINAL, GREEN_GARLANDS, SONIC_SILVER } from 'app/theme/colors';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material';
import { theme } from 'app/theme/theme';

type StatsNumberProps = {
  color: string;
  fontSize: number;
};

export const StatsNumber = styled('span')(
  {
    fontWeight: 800,
    marginLeft: 2,
  },
  (props: StatsNumberProps) => ({
    color: props.color,
    fontSize: props.fontSize,
  })
);

type ArrowWrapperProps = {
  marginBottom: number;
};

type ArrowProps = {
  isSingleBar?: boolean;
};

export const ArrowWrapper = styled('div', {
  shouldForwardProp: (prop) => 'marginBottom' !== prop.toString(),
})(
  {
    display: 'flex',
  },
  (props: ArrowWrapperProps) => ({
    marginBottom: props.marginBottom,
  })
);

export const ArrowUpIcon = styled(ArrowUpwardIcon, {
  shouldForwardProp: (prop) => 'isSingleBar' !== prop.toString(),
})(
  {
    color: GREEN_GARLANDS,
  },
  (props: ArrowProps) => ({
    color: props.isSingleBar ? SONIC_SILVER : GREEN_GARLANDS,
  })
);

export const ArrowDownIcon = styled(ArrowDownwardIcon, {
  shouldForwardProp: (prop) => 'isSingleBar' !== prop.toString(),
})(
  {
    color: CARDINAL,
  },
  (props: ArrowProps) => ({
    color: props.isSingleBar ? SONIC_SILVER : CARDINAL,
  })
);

export const ArrowRightIcon = styled(ArrowForwardIcon)({
  color: SONIC_SILVER,
});

type StatsTitleProps = {
  long: boolean;
};

export const StatsTitle = styled('p', {
  shouldForwardProp: (prop) => prop !== 'long',
})(
  {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 150,
  },
  (props: StatsTitleProps) => ({
    fontSize: props.long ? 12 : 15,
  })
);

type InfoWrapperProps = {
  position: 'vertical' | 'horizontal';
  isReportView?: boolean;
};

export const InfoWrapper = styled('div', {
  shouldForwardProp: (prop) => !['isReportView', 'position'].includes(prop.toString()),
})(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  (props: InfoWrapperProps): CSSProperties | any => ({
    flexDirection: props.position === 'vertical' ? 'column' : 'row',
    paddingRight: props.isReportView ? 0 : '18px',
  })
);

type NullValueProps = {
  isLarge?: boolean;
};

export const NullValue = styled('div', {
  shouldForwardProp: (prop) => 'isLarge' !== prop.toString(),
})((props: NullValueProps) => ({
  ...theme.typography.h5,
  color: SONIC_SILVER,
  marginBottom: props.isLarge ? 90 : 0,
}));
