import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import {
  selectCurrentProjectId,
  selectIsPulseProjectKind,
  selectProjectLiveSettings,
  selectPulseTemplateId,
} from 'reduxStore/project/selectors';
import {
  selectSelectedDepartmentIds,
  selectSingleSelectedDepartmentId,
} from 'reduxStore/departments/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { fetchAIAvatar } from 'reduxStore/ai/asyncActions';

export const useFetchAIAvatar = () => {
  const dispatch = useAppDispatch();
  const isPulseProject = useSelector(selectIsPulseProjectKind);
  const isAISummariesEnabledFeature = useFeatureIsOn('is-ai-summaries-enabled');
  const { hasCompanyAiSummariesEnabled } = useSelector(selectProjectLiveSettings) || {};

  const pulseTemplateId = useSelector(selectPulseTemplateId);
  const pulseId = useSelector(selectCurrentProjectId);
  const selectedDepartmentIds = useSelector(selectSelectedDepartmentIds);
  const singleSelectedDepartmentId = useSelector(selectSingleSelectedDepartmentId);

  const shouldFetchAIAvatar =
    isAISummariesEnabledFeature &&
    isPulseProject &&
    hasCompanyAiSummariesEnabled &&
    pulseTemplateId &&
    pulseId &&
    selectedDepartmentIds.length <= 1;

  useEffect(() => {
    if (shouldFetchAIAvatar) {
      dispatch(
        fetchAIAvatar({ pulseTemplateId, pulseId, departmentId: singleSelectedDepartmentId })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchAIAvatar]);
};
