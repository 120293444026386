import {
  aiSummaryDialogHistoryAtom,
  isAISummaryDialogExpendedAtom,
  isAISummaryDialogOpenAtom,
  isVariableConfidentialityCompareAtom,
} from 'shared/state/atoms';
import { useAtom } from 'jotai';

export const useAISummaryDialogHistoryAtom = () => useAtom(aiSummaryDialogHistoryAtom);

export const useIsAISummaryDialogExpendedAtom = () => useAtom(isAISummaryDialogExpendedAtom);

export const useIsAISummaryDialogOpenAtom = () => useAtom(isAISummaryDialogOpenAtom);

export const useIsVariableConfidentialityCompareAtom = () =>
  useAtom(isVariableConfidentialityCompareAtom);
