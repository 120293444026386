import { AxiosInstance } from 'axios';
import { ProjectKind } from 'model/ProjectKind';
import { ReportAvailability } from 'model/ReportAvailability';
import { PaginatedResponse } from 'model/PaginatedResponse';
import { ResponseRateApi } from 'api/ResponseRateApi';
import { ResponseRate } from 'model/ResponseRate';
import { PulseApi } from 'api/PulseApi';
import { QuestionKind } from 'model/QuestionKind';
import { QuestionsStats } from 'model/Question';
import { SentimentState } from 'model/Sentiment';
import { PulseFrequency } from 'model/PulseFrequency';
import { LifecycleType } from 'model/Lifecycle';
import { mapDtoToProjectListItemModel, ProjectListItemDto } from 'api/dto/ProjectListItem.dto';
import { PaginationDto } from 'api/dto/Pagination.dto';
import { mapProjectDtoToModel, ProjectDto } from 'api/dto/Project.dto';
import { ProjectModel } from 'model/Project.model';
import { ProjectListItemModel } from 'model/ProjectListItem.model';
import { pulseMapper } from 'container/pulseMapper';
import { Pulse } from 'model/Pulse';
import { ProjectLiveSettingsDto, ProjectLiveSettingsModel } from 'api/dto/ProjectLiveSettingsDto';

import { ResponseRateMapper } from '../mapper/ResponseRateMapper';
import { PaginationMapper } from '../mapper/PaginationMapper';
import { ProjectMapper } from '../mapper/ProjectMapper';

export namespace ProjectApi {
  // TODO: entry diverged between list and single refactor this into 2 separated interfaces
  // TODO: think about way to separate project and project template
  export type Entry = {
    id: number;
    name: string;
    companyName: string;
    closedAt: string;
    startedAt: string;
    settings: SettingsEntry;
    kind: ProjectKind;
    pulseTemplateId: number | null;
    pulseProjects?: PulseApi.Entry[];
    pulseOnDemandProjects?: PulseApi.Entry[];
    pulseLifecycleProjects?: PulseApi.Entry[];
    lastPulseId?: number;
    pulseFrequencyId: PulseFrequency | null;
    lifecycleType: LifecycleType;
  };
  export type SettingsEntry = {
    aggregationMonths?: number;
    isOverallScoreShow?: boolean;
    isThreePointScale: boolean;
    logo: string;
    respondentProtection: RespondentProtectionEntry;
    sentimentAnalysis: boolean;
    shareFeedbackWithLineManager: boolean;
    showConsentQuestion: boolean;
  };
  export type SentimentEntry = {
    isLoaded: boolean;
  };

  export type RespondentProtectionEntry = {
    comment: number;
    closed: number;
    mode: number;
    variableConfidentialityThreshold: number;
  };

  export type ReportAvailabilityEntry = {
    departmentId: number;
    feedback: boolean;
    comments: boolean;
  };

  export type QuestionStats = {
    [key in QuestionKind]: {
      questionCount: number;
      answerCount: number;
    };
  };

  /**
   * TODO modify this in story that introduces lifecycle to report-ui
   */
  export type PulseTemplateEntry = ProjectApi.Entry & {
    kind: ProjectKind.PULSE_TEMPLATE;
    pulseOnDemandProject: PulseApi.Entry[];
    pulseLifecycleProjects: PulseApi.Entry[];
  };
}

export class ProjectApi {
  constructor(
    private client: AxiosInstance,
    private projectMapper: ProjectMapper,
    private responseRateMapper: ResponseRateMapper
  ) {}

  list(query: string): Promise<PaginatedResponse<ProjectListItemModel[]>> {
    return this.client
      .get<{ data: ProjectListItemDto[]; pagination: PaginationDto }>(`/api/v1/project${query}`)
      .then((response) => ({
        data: response.data.data.map(mapDtoToProjectListItemModel),
        pagination: PaginationMapper.deserialize(response.data.pagination),
      }));
  }

  get(projectId: number): Promise<ProjectModel> {
    return this.client
      .get<{ data: ProjectDto }>(`/api/v1/project/${projectId}`)
      .then((response) => mapProjectDtoToModel(response.data.data));
  }

  getLastPulseId(projectId: number): Promise<ProjectModel['id'] | null> {
    return this.client
      .get<{ data: ProjectDto }>(`/api/v1/project/${projectId}`)
      .then((response) => this.projectMapper.deserializeLastPulseId(response.data.data))
      .catch((error) => {
        if (error?.response?.status === 404) {
          return null;
        }

        throw error;
      });
  }

  getTemplateProjects(
    pulseTemplateId: number
  ): Promise<{
    pulses: Pulse[];
    onDemand: Pulse[];
    lifecycle: Pulse[];
  }> {
    return this.client
      .get<{ data: ProjectDto }>(`/api/v1/project/${pulseTemplateId}`)
      .then((response) => response.data.data)
      .then((project) => {
        if (project.kind !== ProjectKind.PULSE_TEMPLATE) {
          return { pulses: [], onDemand: [], lifecycle: [] };
        }
        return {
          pulses: project.pulseProjects.map(pulseMapper.deserialize),
          onDemand: project.pulseOnDemandProjects.map(pulseMapper.deserialize),
          lifecycle: project.pulseLifecycleProjects.map(pulseMapper.deserialize),
        };
      });
  }

  listReportAvailability(id: number): Promise<ReportAvailability[]> {
    return this.client
      .get(`/api/v1/project/${id}/report-availability`)
      .then((response) => this.projectMapper.deserializeProjectAvailabilities(response.data));
  }

  getResponseRate(projectId: number, query: string): Promise<ResponseRate> {
    return this.client
      .get<ResponseRateApi.Entry>(`/api/v1/project/${projectId}/report/response-rate${query}`)
      .then(({ data }) => this.responseRateMapper.deserialize(data))
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  getQuestionsStats(projectId: number): Promise<QuestionsStats> {
    return this.client
      .get<{ data: ProjectApi.QuestionStats }>(`/api/v1/project/${projectId}/questions-stats`)
      .then(({ data }) => data.data)
      .catch((error) => {
        throw error.response ? error.response.data.error : error;
      });
  }

  getSentimentState(projectId: number): Promise<SentimentState> {
    return this.client
      .get<{ data: { sentiment: ProjectApi.SentimentEntry | null } }>(
        `/api/v1/project/${projectId}/sentiment`
      )
      .then(({ data: { data } }) => {
        if (!data || !data.sentiment) {
          return SentimentState.Off;
        }

        return data?.sentiment?.isLoaded ? SentimentState.Loaded : SentimentState.Loading;
      })
      .catch(() => {
        return SentimentState.Off;
      });
  }

  getLiveSettings(projectId: number): Promise<ProjectLiveSettingsModel> {
    return this.client
      .get<ProjectLiveSettingsDto>(`/api/survey/${projectId}/live-settings`)
      .then(({ data }) => data);
  }
}
