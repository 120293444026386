import { styled } from '@mui/material';
import { ERROR_LABEL, WHITE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

export const ErrorHandlerWrapper = styled('div')({
  height: '100%',
  width: '100%',
});

type ErrorLabelProps = {
  align?: 'center' | 'flex-start';
};

export const ErrorLabelWrapper = styled('span')(
  {
    ...theme.typography.h5,
    background: WHITE,
    borderRadius: '10px',
    padding: '24px',
    display: 'flex',
    marginTop: 30,
    justifyContent: 'center',
    fontSize: 18,
    color: ERROR_LABEL,
  },
  ({ align = 'center' }: ErrorLabelProps) => ({
    justifyContent: align,
  })
);
