import { getNextAISummaryItemByTitle } from 'util/getNextAISummaryItemByTitle';

import { AIAvatarsModel } from 'api/dto/AIAvatar.dto';
import { AISummariesModel } from 'api/dto/AISummary.dto';

export const getNextAIAvatarItemByTitle = ({
  aiAvatar,
  aiSummary,
  title,
}: {
  aiAvatar: AIAvatarsModel | null;
  aiSummary: AISummariesModel | null;
  title?: string;
}) => {
  const nextAISummaryItemTitle = getNextAISummaryItemByTitle(aiSummary, title)?.title;

  if (!aiAvatar || !title || !nextAISummaryItemTitle) {
    return null;
  }

  return aiAvatar.sections.find((section) => section.title === nextAISummaryItemTitle) ?? null;
};
