import { Resource } from 'model/Resource';
import { AIAvatarItemModel, AIAvatarsModel, AIAvatarStatusEnum } from 'api/dto/AIAvatar.dto';
import { AISummariesModel, AISummaryItemModel } from 'api/dto/AISummary.dto';

export const STORE_NAME = 'ai';

export namespace AIState {
  export type Domain = {
    avatar: Resource<AIAvatarsModel | null>;
    summary: Resource<AISummariesModel | null>;
  };

  export const INITIAL_DOMAIN: AIState.Domain = {
    avatar: Resource.createPendingResource<AIAvatarsModel | null>(),
    summary: Resource.createPendingResource<AISummariesModel | null>(),
  };
}

export const STATIC_AVATARS: AIAvatarItemModel[] = [
  {
    status: AIAvatarStatusEnum.Completed,
    title: 'What Will I Get from My Results?',
    url: 'https://cdn.workbuzz.com/videos/ai-avatars/what-will-I-get-from-my-results.mp4',
  },
];

export const STATIC_SUMMARIES: AISummaryItemModel[] = [
  {
    title: 'What Will I Get from My Results?',
    paragraph: `Hi there!
      Welcome to your team’s engagement survey results review.      
      In this summary, I will help you explore what your results really mean, how they can help you to create an improved working environment and identify what is important to your employees.
      Working through the results, you’ll gain valuable insights into the key areas which will really make a difference to culture and employee engagement across your organisation.
      Firstly we will answer the question of “How engaged is your team?”. We’ll start with a summary of response rates and overall engagement scores, which will tell you how actively your team participated in the survey and give you a sense of their overall connection to their work, their role and the organisation as a whole.      
      Next we will look into “the current mood of your team?”. The survey results will highlight how your team is feeling right now, uncovering what motivates them, things that work well, concerns and frustrations and issues that need attention.
      After this we will look into “what may have changed since the last survey?”. You’ll get a clear picture of what’s different this time around, helping you identify trends over time and areas where you have been successful in positively changing behaviours and process. By running surveys regularly, you will also be able to see how views and opinions are changing and you will be able to quickly identify any new challenges at the earliest opportunity.
      Finally we will tackle how these results can help you make informed decisions. Understanding how your team is doing allows you to take action — whether that’s celebrating improvements or addressing any concerns before they become bigger issues.
      By the end of this review, you’ll be equipped with insights that can help you strengthen engagement, improve team dynamics, and drive positive change.
      Are you Ready to dive in and see what your results reveal? Let’s get started`,
  },
];
