import { Box, styled } from '@mui/material';
import { WHITE } from 'app/theme/colors';
import { BASE_PADDING, MARGIN_SMALL } from 'app/theme/constants';

type SummaryWidgetsWrapperProps = {
  isReportView?: boolean;
  paddingTop?: number;
  marginLeft?: number;
};

export const SummaryWidgetsWrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isReportView', 'paddingTop', 'marginLeft'].includes(prop.toString()),
})(
  {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: MARGIN_SMALL,
  },
  (props: SummaryWidgetsWrapperProps) => ({
    paddingTop: props.isReportView ? props.paddingTop ?? 100 : 0,
    marginLeft: props.isReportView ? props.marginLeft ?? 50 : 0,
  })
);

export type SummaryWidgetsContentProps = {
  isPulseTemplateView?: boolean;
  maxWidth?: number | string;
};

export const SummaryWidgetsContent = styled('div', {
  shouldForwardProp: (prop) => !['isPulseTemplateView', 'maxWidth'].includes(prop.toString()),
})(
  ({ theme }) => ({
    gap: 23,
    width: 'auto',
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  }),
  (props: SummaryWidgetsContentProps) => ({
    padding: props.isPulseTemplateView ? undefined : `${BASE_PADDING}px 0`,
  })
);

type ComponentWrapperProps = {
  index?: number;
  minWidth?: number | string;
  breakBefore?: string;
  breakAfter?: string;
  noPaddings?: boolean;
};

export const ComponentWrapper = styled(Box, {
  shouldForwardProp: (prop) =>
    !['index', 'minWidth', 'breakAfter', 'breakBefore', 'noPaddings'].includes(prop.toString()),
})(
  ({ theme }) => ({
    display: 'flex',
    borderRadius: '10px',
    background: WHITE,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '16px',
    },
  }),
  (props: ComponentWrapperProps) => ({
    minWidth: props.minWidth || 150,
    '@media print': {
      breakBefore: props.breakBefore || 'auto',
      breakAfter: props.breakAfter || 'auto',
    },
    padding: props.noPaddings ? '24px 0 0 0' : '24px',
  })
);

type ComponentLabelProps = {
  length?: number;
  adjustToLeft?: boolean;
  marginBottom?: number;
};

export const ComponentLabel = styled('div', {
  shouldForwardProp: (prop) =>
    !['length', 'adjustToLeft', 'marginBottom'].includes(prop.toString()),
})(
  {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontWeight: 400,
    fontSize: 23,
    textAlign: 'center',
    lineHeight: 1.2,
    whiteSpace: 'normal',
  },
  (props: ComponentLabelProps) => ({
    fontSize: props.length && props.length > 20 ? 20 : 23,
    justifyContent: props.adjustToLeft ? 'flex-start' : 'center',
    marginBottom: props.marginBottom ?? 30,
  })
);

export const PeriodSelectionSliderWrapper = styled('div')({
  marginTop: 24,
  marginBottom: 24,
});
