import { replace } from 'connected-react-router';

import { selectReportOrCurrentProjectId } from 'util/selector/reportSelector';
import { selectProjectsQuery } from 'util/selector/querySelector';

import {
  selectCurrentProjectId,
  selectIsPulseOrTemplateProjectKind,
  selectPulseTemplateId,
} from 'reduxStore/project/selectors';
import { pushQuery } from 'reduxStore/router/asyncActions';
import { Project } from 'model/Project';
import { queryDto } from 'api/dto/Query.dto';
import { ProjectKind } from 'model/ProjectKind';
import { projectUrlBuilder } from 'shared/utils/builders/projectUrlBuilder';
import { projectApi } from 'container/projectApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';
import { ProjectLiveSettingsModel } from 'api/dto/ProjectLiveSettingsDto';
import { projectLiveSettingsModel } from 'model/mocks/projectLiveSettings.mock';

import { setPagination } from './slice';
import { ProjectState, STORE_NAME } from './initialState';

export const fetchProjects = createAsyncThunk(
  `${STORE_NAME}/fetchProjects`,
  (page: number | undefined = undefined, { dispatch, getState }) => {
    const query = selectProjectsQuery(page)(getState());

    return projectApi.list(query).then(({ data, pagination }) => {
      dispatch(setPagination(pagination));
      dispatch(pushQuery({ query: queryDto.stringifyPagination(pagination.index) }));

      return data;
    });
  },
  { serializeError }
);

export const fetchCurrentProject = createAsyncThunk(
  `${STORE_NAME}/fetchCurrentProject`,
  (_, { dispatch, getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    return projectApi.get(projectId).then(async (project) => {
      const shouldRedirectToPulseTemplateChild = project.kind === ProjectKind.PULSE_TEMPLATE;
      if (shouldRedirectToPulseTemplateChild) {
        dispatch(replace(projectUrlBuilder.getDefault(project)));
        return ProjectState.INITIAL_DOMAIN.current.data;
      }

      if (Project.isPulseTemplateChild(project)) {
        const templateProjects = await projectApi.getTemplateProjects(project.pulseTemplateId);
        return {
          ...project,
          pulseProjects: templateProjects.pulses,
          pulseOnDemandProjects: templateProjects.onDemand,
          pulseLifecycleProjects: templateProjects.lifecycle,
        };
      }

      return project;
    });
  },
  { serializeError }
);

export const fetchReportAvailabilities = createAsyncThunk(
  `${STORE_NAME}/fetchReportAvailabilities`,
  (_, { getState }) => {
    const projectId = selectReportOrCurrentProjectId(getState());
    return projectApi.listReportAvailability(projectId);
  },
  { serializeError }
);

export const fetchQuestionsStats = createAsyncThunk(
  `${STORE_NAME}/fetchQuestionsStats`,
  (_, { getState }) => {
    const isPulse = selectIsPulseOrTemplateProjectKind(getState());
    const templateId = selectPulseTemplateId(getState());
    const projectId = selectCurrentProjectId(getState());

    return projectApi.getQuestionsStats(isPulse ? templateId! : projectId);
  },
  { serializeError }
);

export const fetchLastPulseId = createAsyncThunk(
  `${STORE_NAME}/fetchLastPulseId`,
  (_, { getState }) => {
    const pulseTemplateId = selectPulseTemplateId(getState());
    return projectApi.getLastPulseId(pulseTemplateId!);
  },
  { serializeError }
);

export const fetchSentimentState = createAsyncThunk(
  `${STORE_NAME}/fetchSentimentState`,
  (_, { getState }) => {
    const currentProjectId = selectCurrentProjectId(getState());
    return projectApi.getSentimentState(currentProjectId);
  },
  { serializeError }
);

export const fetchProjectLiveSettings = createAsyncThunk<ProjectLiveSettingsModel, number>(
  `${STORE_NAME}/fetchProjectLiveSettings`,
  // TODO: uncomment actual API request instead of the mock value once the endpoint is implemented https://buzzing.atlassian.net/browse/AIS-161
  // (projectId) => projectApi.getLiveSettings(projectId),
  () => projectLiveSettingsModel,
  { serializeError }
);
