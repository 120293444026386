import { AISummariesModel } from 'api/dto/AISummary.dto';

export const getNextAISummaryItemByTitle = (aiSummary: AISummariesModel | null, title?: string) => {
  if (!aiSummary || !title) {
    return null;
  }

  const currentIndex = aiSummary.sections.findIndex((section) => section.title === title);

  return aiSummary?.sections[currentIndex + 1] ?? aiSummary?.sections[0] ?? null;
};
