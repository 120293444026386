import { styled } from '@mui/material';
import { TagVariantEnum } from 'component/Tags/Tag';
import { CINDERELLA, PEACH_UMBRELLA, WARP_DRIVE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

interface Props {
  variant: TagVariantEnum;
}

type TagColorsMap = {
  [key in TagVariantEnum]: string;
};

const TagColors: TagColorsMap = {
  negative: CINDERELLA,
  neutral: PEACH_UMBRELLA,
  positive: WARP_DRIVE,
} as const;

export const TagStyled = styled('span')(
  {
    ...theme.typography.subtitle2,
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: 32,
    fontWeight: 800,
    maxWidth: 270,
    textAlign: 'center',
  },
  (props: Props) => ({
    backgroundColor: TagColors[props.variant],
  })
);
