import { ProjectLiveSettingsDto, ProjectLiveSettingsModel } from 'api/dto/ProjectLiveSettingsDto';

export const projectLiveSettingsDto: ProjectLiveSettingsDto = {
  languageCodes: ['en_GB'],
  translateComments: false,
  hasCompanyAiSummariesEnabled: true,
  isExecutiveSummaryEnabled: true,
  isDepartmentSummaryEnabled: true,
};

export const projectLiveSettingsModel: ProjectLiveSettingsModel = projectLiveSettingsDto;
