import { styled } from '@mui/material';
import { WHITE, WORKBUZZ_ORANGE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

export const SummaryInfoTitle = styled('h5')({
  ...theme.typography.subtitle2,
});

export const SummaryInfoWrapper = styled('div')({
  color: WHITE,
});

export const SummaryInfoContent = styled('span')({
  ...theme.typography.subtitle2,
  color: WHITE,
});

export const SummaryInfoLink = styled('span')({
  display: 'block',
  marginTop: '5px',

  textDecoration: 'none',
  color: WORKBUZZ_ORANGE,
  '&:hover': {
    cursor: 'pointer',
  },
});
