import { Box, styled, Typography } from '@mui/material';
import { WHITE, WORKBUZZ_BLUE } from 'app/theme/colors';

export const AIDialogContentsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

export const AIDialogQuestion = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  padding: '8px 12px',
  alignItems: 'flex-start',
  alignSelf: 'stretch',
  color: WHITE,
  backgroundColor: WORKBUZZ_BLUE,
  borderRadius: '6px',
  marginLeft: '64px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '104px',
  },
}));

export const AIDialogAnswerCard = styled(Box)(({ theme }) => ({
  backgroundColor: WHITE,
  borderRadius: '6px',
  marginLeft: '36px',
  marginRight: '24px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '40px',
    marginRight: '64px',
  },
}));

export const AIDialogAnswerNextSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: '8px',
  marginRight: '24px',
  [theme.breakpoints.up('md')]: {
    marginRight: '64px',
  },
}));

export const AIDialogAnswerAILogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: WHITE,
  borderRadius: '50%',
  width: '28px',
  height: '28px',
  [theme.breakpoints.up('md')]: {
    width: '32px',
    height: '32px',
  },
}));
