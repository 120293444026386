export const DARK_ORANGE = '#f36d2b';
export const ORANGE = '#f89a1f';
export const LIGHT_ORANGE = '#f9b55b';
export const GREY_TEXT = '#444446';
export const DARK_GREY_TEXT = '#2c2c2c';
export const WHITE = '#FFFFFF';
export const GREY_BACKGROUND = '#333333';
export const LIGHT_GREY = '#5c5c5c';
export const LIGHTER_GREY = '#808080';
export const THE_LIGHTEST_GREY = '#CBCBCB';
export const PLACEHOLDER_GREY = '#484848';
export const PLACEHOLDER_FOCUS_GREY = '#a3a3a3';
export const CHART_TOOLTIP_BORDER_GREY = '#cccccc';

export const TEXT_COLOR = '#212121';
export const BOULDER = '#757575';
//Alternative name in designs is Bnei Brak Bay
export const MARINER = '#2056CF';
export const GRAY_NURSE = '#dce0d9';
export const MIDNIGHT_EXPRESS = '#222B3A';

// TABLE
export const GREY_CELL = '#E0E3DE';
export const GREY_CELL_TEXT = '#212121';
export const RESTRICTED_COLUMN = '#E0E3DE';
export const RESTRICTED_COLUMN_TEXT = '#8c8c8c';
export const OVERALL_COLUMN = '#a5ac52';

// BUTTONS
export const BUTTON_GREY = '#424244';
export const BUTTON_ORANGE = '#f57e26';
export const BUTTON_BORDER = '#d4d4d4';
export const BUTTON_SHADOW = 'rgba(173, 173, 173, 0.47)';
export const GREEN_BUTTON = '#83b348';
export const ORANGE_BUTTON = '#f57e26';

// PERCENTAGE BAR
export const START_ORANGE = '#f17828';
export const MIDDLE = '#f48d22';
export const END_ORANGE = '#f15a30';
export const CIRCLE_GREY = '#cccccc';
export const CIRCLE_HOVER_GREY = '#bbbbbb';

// BAR CHART
export const POSITIVE = '#7DB152';
export const LINE = '#dfdfdf';

// SCROLL BAR
export const LIGHT_SCROLL_BACKGROUND = '#e1e1e1';
export const LIGHT_SCROLL_TRACK = '#bbbbbb';
export const SCROLL_BAR_BACKGROUND = '#4a4a4a';
export const SCROLL_BAR_TRACK = '#909090';

// ENGAGEMENT BAR
export const ENGAGEMENT_GREY = '#cccccc';
export const ENGAGEMENT_GREEN = '#83b348';
export const VIEW_DETAILS_GREY = '#686868';

// QUESTION TABLE
export const FLAG_BLUE = '#32B0F0';

// REPORT
export const ORANGE_TEXT = '#f57a14';
export const GREY_LINE = '#E5E5E5';
export const COMMENT_TEXT = '#515C7D';

// COMMENT
export const BORDER_ANSWER = '#e3e4e5';
export const BACKGROUND_COMMENT = '#ecedef';
export const SEARCH_TEXT = '#626263';
export const SEARCH_ICON = '#9e9e9f';

// INSIGHTS
export const INSIGHT_BAR_GREEN = '#7DB152';
export const INSIGHT_BAR_BACKGROUND = '#E4E4E4';
export const ERROR_LABEL = '#66686b';
export const TOOLTIP_SHADOW = '#c1c1c1';

// ICONS
export const GREY_ICON = '#BEBEBE';

// DASHBOARD REDESIGN ----------------------------
// PAGE
export const PAGE_BACKGROUND = '#F1F3F0';

// WORKBUZZ_BLUE
export const WORKBUZZ_BLUE = '#3D5A80';
export const BLUE_HORIZON = '#566A8A';

// WORKBUZZ_ORANGE
export const WORKBUZZ_ORANGE = '#E07400';
export const WORKBUZZ_ORANGE_BAR_BACKGROUND = '#F9E3CC';
export const CALIFORNIA = '##E9913B';

// BLACK
export const BLACK = '#212121';
export const DARK_GREY = '#373737';

// PLATINUM
export const PLATINUM = '#dce0d9';
export const BARELY_WHITE = '#E0E3DE';
export const IRON = '#D8D9DD';
export const SONIC_SILVER = '#757575';
export const ASH_IN_THE_AIR = '#D9DEE5';
export const LINK_WATER = '#C6CDD8';

export const GREEN_GARLANDS = '#008577';
export const CARDINAL = '#D3232F';
export const CARDINAL_BAR_BACKGROUND = '#F6D3D5';

// Sentiment
export const VIRIDIAN = '#3A8377';
export const SHADOW_GREEN = '#8FC0BB';
export const EDGEWATER = '#C6E0DD';
export const SIDECAR = '#F3DDC2';
export const GOLD_SAND = '#E7BB88';
export const DI_SERRIA = '#DC9B51';
export const PETITE_ORCHID = '#DD9599';
export const CHESTNUT_ROSE = '#CE6266';
export const FLUSH_MAHOGANY = '#C23737';

// HEAT MAP
export const TEAL_80 = '#008577CC';
export const TEAL_60 = '#00857799';
export const TEAL_25 = '#00857740';
export const MANGO_TANGO_25 = '#e0740040';
export const MANGO_TANGO_50 = '#e0740080';
export const MANGO_TANGO_75 = '#e07400bf';
export const CARDINAL_50 = '#d3232f80';
export const CARDINAL_60 = '#d3232f99';
export const CARDINAL_70 = '#d3232fb3';

// SENTIMENT
export const SENTIMENT_BACKGROUND_POSITIVE = '#E8F3F1';
export const SENTIMENT_BACKGROUND_NEUTRAL = '#F9E6CF';
export const SENTIMENT_BACKGROUND_NEGATIVE = '#FDE7E7';
export const SENTIMENT_BACKGROUND_DEFAULT = '#F7F7F7';

// OTHER
export const PROGRESS_BAR_BLUE = '#d2ddf5';
export const REGENT_GRAY = '#7F89A3';
export const OXFORD_BLUE = '#3A4254';
export const WARP_DRIVE = '#E8F3F1';
export const PEACH_UMBRELLA = '#F9E6CF';
export const CINDERELLA = '#FDE7E7';
export const GREEN_HAZE = '#00A84F';
export const CORNFLOWER_BLUE = '#e6eeff';
export const HARD_COAL = '#646464';
export const BACK_LIGHT = '#FCF2E6';

// FEEDBACK_BAR
export const WARP_DRIVE_DARKER = '#9acac1';
export const GREEN_HAZE_DARKER = '#007e3b';
export const CINDERELLA_DARKER = '#f47777';
export const CARDINAL_DARKER = '#9e1a23';
