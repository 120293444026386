import { SxProps, styled } from '@mui/material';
import { OXFORD_BLUE, REGENT_GRAY } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

export const NavLink = styled('span')({
  borderBottom: `1px solid ${REGENT_GRAY}`,

  '&:hover': {
    cursor: 'pointer',
  },
});

export const Divider = styled('span')({
  ...theme.typography.h6,
  color: REGENT_GRAY,
  margin: '0 5px',
});

export const navLinkContainerSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  color: OXFORD_BLUE,
};
