import { AxiosInstance } from 'axios';
import { generateAvatarId } from 'shared/utils/api/generateAvatarId';
import { AIAvatarsDto, AIAvatarsModel, mapAIAvatarsDtoToModel } from 'api/dto/AIAvatar.dto';

export class AIAvatarServiceApi {
  constructor(private client: AxiosInstance) {}

  get({
    pulseTemplateId,
    pulseId,
    departmentId,
  }: {
    pulseTemplateId: number;
    pulseId: number;
    departmentId?: number;
  }): Promise<AIAvatarsModel> {
    const avatarId = generateAvatarId({ pulseTemplateId, pulseId, departmentId });
    return this.client
      .get<AIAvatarsDto>(`/api/v1/avatar/${avatarId}`)
      .then((response) => mapAIAvatarsDtoToModel(response.data));
  }
}
