import { styled } from '@mui/material';
import Icon from 'react-icons/lib/md/search';
import { IRON, SEARCH_ICON, SONIC_SILVER, WHITE } from 'app/theme/colors';

export const SearchInputWrapper = styled('div')(({ theme }) => ({
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  position: 'relative',
  marginRight: '52px',
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
    marginBottom: '16px',
    width: 'auto',
  },
}));

export const SearchIcon = styled(Icon)({
  position: 'absolute',
  top: 15,
  right: 0,
  margin: '0 15px',
  zIndex: 1,
  fontSize: 18,
  color: SEARCH_ICON,
});

export const SearchInputElement = styled('input')(({ theme }) => ({
  ...theme.typography.body1,
  color: SONIC_SILVER,
  fontFamily: theme.typography.fontFamily,
  width: '270px',
  height: '53px',
  background: WHITE,
  border: `2px solid ${IRON}`,
  borderRadius: '6px',
  float: 'left',
  padding: '12px 16px',
  '&:focus': {
    outline: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
