import { v5 } from 'uuid';

export const generateAvatarId = ({
  pulseTemplateId,
  pulseId,
  departmentId = -1,
}: {
  pulseTemplateId: number;
  pulseId: number;
  departmentId?: number;
}) => {
  const avatarNamespace = 'e0a96c80-9b4f-4e38-bf92-2388e4cf5b43';
  const key = `${pulseTemplateId}_${pulseId}_${departmentId}`;

  return v5(key, avatarNamespace);
};
