import React from 'react';

import { InfoIcon } from 'util/withTooltip';

import MUITooltip from '@mui/material/Tooltip';
import { ImpactOnEngagementInfo } from 'component/ImpactOnEngagementInfo/ImpactOnEngagementInfo';
import { Benchmark } from 'model/Benchmark';
import {
  ColumnName,
  SortBy,
} from 'view/EngagementDriverByCategoryPage/SortableQuestionTable/utils';
import { Box, TableCell, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { theme } from 'app/theme/theme';

import { HeaderCell, HeaderCellContent, HeadRow } from './SortableQuestionHeader.s';

export namespace SortableQuestionHeader {
  export type Props = {
    showImpactOnEngagement: boolean;
    isPulseTemplate: boolean;
    isReportView?: boolean;
    benchmark?: Benchmark;
    shouldRenderCategory?: boolean;
    shouldDisplayTrendPlotDataColumn?: boolean;
    sortBy: SortBy;
    onSort: (column: ColumnName | string) => void;
    trendLabels?: string[];
  };
}

export const SortableQuestionHeader = (props: SortableQuestionHeader.Props): React.JSX.Element => {
  const renderHeaderCell = ({
    column,
    padding = '0',
    label,
    tooltip,
  }: {
    column: ColumnName | string;
    padding?: string;
    label?: string;
    tooltip?: React.JSX.Element | null;
  }) => {
    return (
      <TableCell
        key={column}
        align="left"
        sortDirection={props.sortBy.column === column ? props.sortBy.order : false}
        style={{
          ...theme.typography.h6,
          verticalAlign: 'middle',
          textAlign: 'center',
          borderBottom: 'none',
          padding,
          maxWidth: props.isReportView ? '130px' : 'auto',
          aspectRatio: undefined,
          whiteSpace: undefined,
        }}
      >
        <TableSortLabel
          data-testid="headerLabel"
          active={props.sortBy.column === column}
          direction={props.sortBy.column === column ? props.sortBy.order : 'asc'}
          onClick={() => props.onSort(column)}
          hideSortIcon={props.isReportView}
        >
          {label || column}
          {props.sortBy.column === column ? (
            <Box component="span" sx={visuallyHidden}>
              {props.sortBy.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          ) : null}
          {tooltip}
        </TableSortLabel>
      </TableCell>
    );
  };

  return (
    <thead data-testid="questionTableHeader">
      <HeadRow>
        {props.shouldRenderCategory && renderHeaderCell({ column: ColumnName.Category })}
        {renderHeaderCell({
          column: ColumnName.Question,
          padding: '16px 4px 16px 16px',
        })}
        {props.showImpactOnEngagement &&
          renderHeaderCell({
            column: ColumnName.Impact,
            label: 'Impact',
            tooltip: !props.isReportView ? (
              <MUITooltip
                title={<ImpactOnEngagementInfo />}
                placement="top"
                enterTouchDelay={0}
                leaveTouchDelay={15000}
                arrow
              >
                <InfoIcon data-testid="impactOnEngagementInfoIcon" />
              </MUITooltip>
            ) : null,
          })}
        <HeaderCell sx={{ width: '200px' }} data-testid="headerLabel">
          Feedback
        </HeaderCell>
        {renderHeaderCell({ column: ColumnName.Score, padding: '16px' })}
        {props.trendLabels?.map((label) => renderHeaderCell({ column: label, label }))}
        {Benchmark.isNotNone(props.benchmark)
          ? renderHeaderCell({ column: ColumnName.Benchmark, label: props.benchmark.label })
          : null}
        {props.shouldDisplayTrendPlotDataColumn && (
          <HeaderCell>
            <HeaderCellContent data-testid="headerLabel">6 Month Trend</HeaderCellContent>
          </HeaderCell>
        )}
      </HeadRow>
    </thead>
  );
};
