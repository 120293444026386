import React from 'react';

import { withDefaultProps } from 'util/withDefaultProps';

import * as d3 from 'd3';
import { PieChart } from 'model/PieChart';
import { PieArcDatum } from 'd3';

export namespace Slice {
  export type Props = {
    item: PieArcDatum<PieChart>;
    fill: string;
    outerRadius: number;
    innerRadius: number;
  };
}

export const SlicePure: React.FC<Slice.Props> = (props) => {
  const arc = d3
    .arc<PieArcDatum<PieChart>>()
    .innerRadius(props.innerRadius)
    .outerRadius(props.outerRadius);

  const textArc = d3
    .arc<PieArcDatum<PieChart>>()
    .innerRadius(props.outerRadius / 2)
    .outerRadius(props.outerRadius);

  return (
    <g
      data-tip={`
        <div>
          <p style="color: white; font-size: 14px">${props.item.value.toFixed(2)}%</p>
        </div>
       `}
    >
      <path d={arc(props.item)!} fill={props.fill} />
      {props.item.value !== 0 && (
        <text
          dy=".35em"
          transform={
            props.item.value < 10
              ? `translate(${textArc.centroid(props.item)})`
              : `translate(${arc.centroid(props.item)})`
          }
          textAnchor="middle"
          fill="white"
          fontSize={17}
          fontWeight={600}
          data-testid="sliceText"
        >
          {props.item.value.toFixed(0)}
          {props.item.value >= 4 ? '%' : ''}
        </text>
      )}
    </g>
  );
};

const defaultProps = {
  innerRadius: 0,
};

export const Slice = withDefaultProps(defaultProps as Slice.Props, SlicePure);
