import React from 'react';
import { useSelector } from 'react-redux';

import { Dropdown } from 'component/Dropdown/Dropdown';
import { DropdownItem } from 'component/Dropdown/Dropdown.s';
import { downloadInsightsReport } from 'reduxStore/report/asyncActions';
import { DocumentType } from 'model/DocumentType';
import { PageWrapperHeader } from 'component/PageHeader/PageHeader.s';
import { PageTitle } from 'component/PageHeaderTitle/PageTitle';
import { DocumentIcon } from 'component/DocumentIcon/DocumentIcon';
import { ReportFormat, ReportFormatType } from 'model/ReportFormat';
import { selectHasInsightsResolved } from 'reduxStore/insights/selectors';
import { useAppDispatch } from 'reduxStore/appStore';
import { selectIsPendingReport } from 'reduxStore/report/selectors';
import { getReportActionNameByReportFormat } from 'reduxStore/report/getReportActionName';
import { selectShouldShowDownloadButton } from 'reduxStore/filtering/selectors';
import { useIsVariableConfidentialityCompareAtom } from 'shared/hooks/useAtoms';

export type InsightsHeaderProps = {
  title?: string;
};

export const InsightsHeader: React.FC<InsightsHeaderProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const [isVariableConfidentialityCompare] = useIsVariableConfidentialityCompareAtom();
  const hasInsightsResolved = useSelector(selectHasInsightsResolved);
  const shouldShowPDFandPPT = useSelector(selectShouldShowDownloadButton);

  const usePendingReportStatus = (type: ReportFormatType) =>
    useSelector(
      selectIsPendingReport(getReportActionNameByReportFormat(downloadInsightsReport, { type }))
    );
  const isExcelDownloadPending = usePendingReportStatus(ReportFormat.Excel);
  const isPowerPointDownloadPending = usePendingReportStatus(ReportFormat.PowerPoint);
  const isPdfDownloadPending = usePendingReportStatus(ReportFormat.PDF);

  const onReportDownload = (type: ReportFormatType) => dispatch(downloadInsightsReport({ type }));

  return (
    <PageWrapperHeader data-testid="insightsHeader">
      <PageTitle title={title || 'Insights'} data-testid="insightsHeaderTitle" />
      {hasInsightsResolved && !isVariableConfidentialityCompare && (
        <Dropdown label="Download Report" data-testid="downloadReportDropdown">
          <DropdownItem
            data-testid="downloadReportExcel"
            onClick={() => onReportDownload(ReportFormat.Excel)}
          >
            <DocumentIcon type={DocumentType.Excel} isDownloadPending={isExcelDownloadPending} />
            Excel
          </DropdownItem>
          {shouldShowPDFandPPT && (
            <>
              <DropdownItem
                data-testid="downloadReportPDF"
                onClick={() => onReportDownload(ReportFormat.PDF)}
              >
                <DocumentIcon type={DocumentType.PDF} isDownloadPending={isPdfDownloadPending} />
                PDF
              </DropdownItem>
              <DropdownItem
                data-testid="downloadReportPowerpoint"
                onClick={() => onReportDownload(ReportFormat.PowerPoint)}
              >
                <DocumentIcon
                  type={DocumentType.PowerPoint}
                  isDownloadPending={isPowerPointDownloadPending}
                />
                PowerPoint
              </DropdownItem>
            </>
          )}
        </Dropdown>
      )}
    </PageWrapperHeader>
  );
};
