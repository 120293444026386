import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import {
  ColorCell,
  GreyCell,
  HiddenValue,
  LabelCell,
  LabelCellContent,
  LessThanColumn,
  TableRow,
} from 'component/CompareMatrix/Row/Row.s';
import { SubmitType } from 'model/SubmitType';
import { Box, Tooltip } from '@mui/material';
import MdInfoOutline from 'react-icons/lib/md/info-outline';
import { ComparisionMatrix } from 'model/ComparisionMatrix';
import { FlagNew } from 'component/FlagNew/FlagNew';
import { selectTableBreadth } from 'reduxStore/departments/selectors';
import isNumber from 'lodash/isNumber';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SONIC_SILVER } from 'app/theme/colors';
import { useIsVariableConfidentialityCompareAtom } from 'shared/hooks/useAtoms';

export type RowProps = {
  values: ComparisionMatrix.Value;
  label: string;
  formatType: string;
  columnNumber: number;
  labelIndex: number;
  labelsNumber: number;
  colorType: ComparisionMatrix.ColorType;
  minResponses: number;
  isLatest?: boolean;
  categoryId?: number | null;
  onCategoryClick?: (id: number) => void;
  isReportView?: boolean;
  submitType?: SubmitType;
  respondentProtectionMessage: string;
};

export type RowPureProps = {
  breadth: ComparisionMatrix.Breadth;
} & RowProps;

export function renderValueCell(
  value: number,
  formatType: string,
  colorType: ComparisionMatrix.ColorType,
  labelIndex: number,
  index: number,
  type?: SubmitType | any
): React.JSX.Element {
  switch (colorType) {
    case 'color':
    case 'mixed':
    case 'mixedBlue':
    case 'byScale':
      return index === 0 || (labelIndex === 0 && colorType === 'mixed') ? (
        <GreyCell
          data-testid="compareMatrixGreyCell"
          data-cy="compareMatrixCell"
          key={index}
          index={index}
        >
          {renderValue(value, formatType)}
        </GreyCell>
      ) : (
        <ColorCell
          data-testid="compareMatrixColorCell"
          data-cy="compareMatrixCell"
          key={index}
          value={value}
          colorType={colorType}
          type={type}
        >
          {renderValue(value, formatType)}
        </ColorCell>
      );

    case 'grey':
      return (
        <GreyCell
          data-testid="compareMatrixGreyCell"
          data-cy="compareMatrixCell"
          key={index}
          index={index}
        >
          {renderValue(value, formatType)}
        </GreyCell>
      );

    default:
      return <></>;
  }
}

export function renderValue(value: number, formatType: string): string {
  return formatType === 'number' ? value.toFixed() : value.toFixed() + '%';
}

export function renderTooltipText(isCategory: boolean, minResponse: number) {
  let questionPhrase = isCategory ? '' : ' this question';

  return `For this group, less than ${minResponse} people answered${questionPhrase}, so the results are restricted`;
}

export const RowPure: React.FC<RowPureProps> = (props) => {
  const [isVariableConfidentialityCompare] = useIsVariableConfidentialityCompareAtom();
  const variableRespondentProtectionMessage =
    'Answers are restricted to protect smaller groups in this demographic.';

  const shouldRenderConfidentialityProtectedCell = (
    item: number | null,
    index: number
  ): boolean => {
    return item === null && props.values.isConfidentialityProtection[index];
  };

  const isFirstCellInColumn = (): boolean => props.labelIndex === 0;

  const renderRow = (item: number | null, index: number): ReactNode => {
    if (props.values.respondentProtectionTypeArray[index]) {
      if (!props.isReportView) {
        return isFirstCellInColumn() ? (
          <LessThanColumn
            data-testid="compareMatrixProtectedColumnCell"
            data-cy="compareMatrixCell"
            key={index}
            rowSpan={props.labelsNumber}
            index={index}
          >
            <Box data-testid="respondentProtectionMessageCompare">
              <Tooltip
                placement="top"
                title={
                  isVariableConfidentialityCompare
                    ? variableRespondentProtectionMessage
                    : props.respondentProtectionMessage
                }
                arrow
                enterDelay={0}
              >
                <VisibilityOffIcon sx={{ color: SONIC_SILVER }} />
              </Tooltip>
            </Box>
          </LessThanColumn>
        ) : null;
        // return null because we have returned rowSpan={props.labelsNumber} in the first row (responded protection)
      }

      return (
        <HiddenValue data-testid="compareMatrixReportViewProtectedCell" key={index} index={index}>
          -
        </HiddenValue>
      );
    }

    if (shouldRenderConfidentialityProtectedCell(item, index)) {
      return (
        <HiddenValue key={index} index={index}>
          <Tooltip
            data-testid="compareMatrixConfidentialityProtectedCell"
            title={renderTooltipText(!!props.categoryId, props.minResponses)}
            arrow
            enterDelay={0}
          >
            <MdInfoOutline size={25} />
          </Tooltip>
        </HiddenValue>
      );
    }

    if (isNumber(item)) {
      return renderValueCell(
        item,
        props.formatType,
        props.colorType,
        props.labelIndex,
        index,
        props.submitType
      );
    }

    return (
      <GreyCell
        data-testid="compareMatrixGreyCell"
        data-cy="compareMatrixCell"
        key={index}
        index={index}
      >
        -
      </GreyCell>
    );
  };

  return (
    <TableRow data-testid="compareMatrixTableRow">
      <LabelCell scope="col">
        <LabelCellContent
          data-testid="compareMatrixRowLabel"
          onClick={() => props.categoryId && props.onCategoryClick?.(props.categoryId)}
          isClickable={!!props.categoryId}
          variant="body2"
          as="span"
        >
          {props.label}
        </LabelCellContent>
        <FlagNew isLatest={props.isLatest} />
      </LabelCell>
      {props.values.value.map(renderRow)}
    </TableRow>
  );
};

export const Row: React.FC<RowProps> = (props) => {
  const breadth = useSelector(selectTableBreadth);

  return <RowPure {...props} breadth={breadth} />;
};
