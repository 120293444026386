export type AISummaryItemDto = {
  title: string;
  paragraph: string;
};

export type AISummariesDto = {
  id: string;
  sections: AISummaryItemDto[];
};

export type AISummaryItemModel = AISummaryItemDto;

export type AISummariesModel = {
  id: string;
  sections: AISummaryItemModel[];
};

export const mapAISummaryItemDtoToModel = (dto: AISummaryItemDto): AISummaryItemModel => ({
  paragraph: dto.paragraph,
  title: dto.title,
});

export const mapAISummariesDtoToModel = (dto: AISummariesDto): AISummariesModel => ({
  id: dto.id,
  sections: dto.sections.map(mapAISummaryItemDtoToModel),
});
