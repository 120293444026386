import { selectAIAvatarApiUrl } from 'util/selector/apiSelector';

import axios, { AxiosInstance } from 'axios';
import { logInterceptedError } from 'shared/hooks/useInitializeDataDogLogs';
import { AUTH_TOKEN_COOKIE } from 'shared/constants/cookies';
import Cookies from 'universal-cookie';

export const aiAvatarClient: AxiosInstance = axios.create({
  baseURL: selectAIAvatarApiUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'en-GB',
    Accept: 'application/json',
  },
});

aiAvatarClient.interceptors.request.use((config) => {
  const authToken = new Cookies().get(AUTH_TOKEN_COOKIE);

  if (authToken && config.headers) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }

  return config;
});

aiAvatarClient.interceptors.response.use(
  (response) => response,
  (error) => {
    logInterceptedError(error);
  }
);
