export const scrollToLastElement = (
  selector: string,
  scrollIntoViewOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' }
) => {
  setTimeout(() => {
    const elements = document.querySelectorAll(selector);

    if (elements.length > 0) {
      const lastElement = elements[elements.length - 1];
      lastElement.scrollIntoView(scrollIntoViewOptions);
    } else {
      console.warn('No elements found for the given selector.');
    }
  });
};
