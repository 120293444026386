import { IRON, MARINER, WHITE } from 'app/theme/colors';
import { Button, styled } from '@mui/material';
import { theme } from 'app/theme/theme';

export const AppWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '100vh',
});

export const MainWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'stretch',
  alignContent: 'stretch',
  height: 'auto',
  position: 'relative',
});

export const LeftMenuWrapper = styled('aside')(({ theme }) => ({
  minWidth: 290,
  maxWidth: 295,
  minHeight: '85vh',
  zIndex: 100,
  backgroundColor: WHITE,
  borderRight: `1px solid ${IRON}`,
  [theme.breakpoints.down('lg')]: {
    minHeight: 'initial',
    flex: '1 1 0',
  },
}));

export const ContentWrapper = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    paddingTop: '30px',
  },
}));

export const ContentBodyWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  paddingBottom: 30,
});

export const FiltersToggleButton = styled(Button)({
  ...theme.typography.subtitle1,
  fontWeight: 800,
  color: MARINER,
  background: WHITE,
  border: `2px solid ${IRON}`,
  borderRadius: '6px',

  position: 'absolute',
  top: '16px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'calc(100vw - 70px)',
  textTransform: 'none',
  ['&:hover']: {
    backgroundColor: WHITE,
  },
  ['&:active']: {
    backgroundColor: WHITE,
  },
  ['&:focused']: {
    backgroundColor: WHITE,
  },
});
