import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedDepartmentIds } from 'reduxStore/departments/selectors';
import { useAIDialogHistory } from 'shared/hooks/useAIDialogHistory';
import { useDebounceValue } from 'shared/hooks/useDebounceValue';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';

export const useClearAIDialogHistory = () => {
  const selectedDepartmentIdsString = useSelector(selectSelectedDepartmentIds).toString();
  const selectedDepartmentIdsStringDebounced = useDebounceValue(
    selectedDepartmentIdsString,
    FILTERS_CHANGED_DEBOUNCE_TIMEOUT
  );

  const { removeHistory } = useAIDialogHistory();

  useEffect(() => {
    removeHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartmentIdsStringDebounced]);
};
