import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { Pagination } from 'model/Pagination';

import {
  fetchCurrentProject,
  fetchLastPulseId,
  fetchProjects,
  fetchProjectLiveSettings,
  fetchQuestionsStats,
  fetchReportAvailabilities,
  fetchSentimentState,
} from './asyncActions';
import { ProjectState, STORE_NAME } from './initialState';

export const projectSlice = createSlice({
  name: STORE_NAME,
  initialState: ProjectState.INITIAL_DOMAIN,
  reducers: {
    clearProject(state) {
      state.current = ProjectState.INITIAL_DOMAIN.current as Draft<ProjectState.Domain['current']>;
    },
    setPagination(state, action: PayloadAction<Pagination>) {
      state.pagination = action.payload;
    },
  },
  extraReducers: (builder) => {
    addAsyncCases(
      builder,
      fetchProjects,
      (state, action) => (state.list = action.payload as Draft<ProjectState.Domain['list']>),
      ProjectState.INITIAL_DOMAIN.list.data
    );
    addAsyncCases(
      builder,
      fetchCurrentProject,
      (state, action) => (state.current = action.payload as Draft<ProjectState.Domain['current']>),
      ProjectState.INITIAL_DOMAIN.current.data
    );
    addAsyncCases(
      builder,
      fetchQuestionsStats,
      (state, action) =>
        (state.questionsStats = action.payload as ProjectState.Domain['questionsStats'])
    );
    addAsyncCases(
      builder,
      fetchReportAvailabilities,
      (state, action) =>
        (state.reportAvailabilities = action.payload as ProjectState.Domain['reportAvailabilities'])
    );
    addAsyncCases(
      builder,
      fetchLastPulseId,
      (state, action) =>
        (state.currentTemplateLastPulseId = action.payload as ProjectState.Domain['currentTemplateLastPulseId'])
    );
    addAsyncCases(
      builder,
      fetchSentimentState,
      (state, action) =>
        (state.sentiment =
          (action.payload as ProjectState.Domain['sentiment']) ||
          ProjectState.INITIAL_DOMAIN.sentiment),
      ProjectState.INITIAL_DOMAIN.sentiment.data
    );
    addAsyncCases(builder, fetchProjectLiveSettings, (state, action) => {
      state.liveSettings = action.payload;
    });
  },
});

export const { clearProject, setPagination } = projectSlice.actions;
