const { v5 } = require('uuid');

export const generateSummaryId = ({
  pulseTemplateId,
  pulseId,
  departmentId = -1,
}: {
  pulseTemplateId: number;
  pulseId: number;
  departmentId?: number;
}) => {
  const summaryNamespace = 'e0a96c80-9b4f-4e38-bf92-2388e4cf5b42';
  const key = `${pulseTemplateId}_${pulseId}_${departmentId}`;

  return v5(key, summaryNamespace);
};
