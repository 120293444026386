import React from 'react';
import { useSelector } from 'react-redux';

import { scrollTop } from 'util/scrollTop';
import { selectFiltersQuery } from 'util/selector/querySelector';
import { InfoIcon } from 'util/withTooltip';

import { ProjectKind } from 'model/ProjectKind';
import { PercentageBar } from 'component/PercentageBar/PercentageBar';
import {
  ComponentLabel,
  ComponentWrapper,
  SummaryWidgetsContent,
  SummaryWidgetsWrapper,
} from 'view/SummaryPage/SummaryWidgets/SummaryWidgets.s';
import { Trend } from 'model/Trend';
import { selectBenchmark, selectScore, selectTrends } from 'reduxStore/summary/selectors';
import { PercentageBarType } from 'model/PercentageBarType';
import {
  selectCurrentProjectId,
  selectIsOverallScoreShow,
  selectProjectKind,
} from 'reduxStore/project/selectors';
import { ProjectKindLabel } from 'component/ProjectKindLabel/ProjectKindLabel';
import { ScrollBar } from 'component/ScrollBar/ScrollBar';
import { SummaryInfo } from 'view/SummaryPage/SummaryInfo/SummaryInfo';
import { AppRoute } from 'app/route/app';
import { routeWithQuery } from 'reduxStore/router/asyncActions';
import { selectIsAdmin } from 'reduxStore/user/selectors';
import { ResponseRateWidget } from 'view/ReportView/SummaryWidgetReport/ResponseRateWidget';
import { TrendWidget } from 'view/SummaryPage/SummaryWidgets/TrendWidget/TrendWidget';
import { BenchmarkWidget } from 'view/SummaryPage/SummaryWidgets/BenchmarkWidget/BenchmarkWidget';
import { setEngagementDriverByCategory } from 'reduxStore/engagementDrivers/asyncActions';
import { selectShouldShowEngagementScore } from 'reduxStore/engagementDrivers/selectors';
import { selectChartLabel } from 'reduxStore/visualSettings/selectors';
import { Benchmark } from 'model/Benchmark';
import { Score } from 'model/Score';
import { ProjectSummary } from 'model/ProjectSummary';
import { useAppDispatch } from 'reduxStore/appStore';
import Tooltip from '@mui/material/Tooltip';

export type SummaryCombineWidgetsProps = {
  isReportView?: boolean;
};

export type SummaryCombineWidgetsPureProps = SummaryCombineWidgetsProps & {
  onProjectLabelClick: (projectId: number, categoryId: number) => void;
  projectKind: ProjectKind;
  query: string;
  projectId: number;
  isUserAdmin: boolean;
  score?: Score;
  benchmark?: Benchmark;
  trends?: ReadonlyArray<Trend>;
  showEngagementScore: boolean;
  isOverallShow: boolean;
  chartLabel: string | null;
};

export const SummaryCombineWidgetsPure: React.FC<SummaryCombineWidgetsPureProps> = (props) => {
  const shouldShowTooltip =
    props.showEngagementScore ||
    (!props.isReportView && props.projectKind !== ProjectKind.PROJECT_OVERALL_SCORE);

  const isEngagementScore =
    props.projectKind === ProjectKind.PROJECT_ENGAGEMENT_SCORE || props.showEngagementScore;

  const renderComponentLabel = () => {
    return (
      <ComponentLabel>
        {isEngagementScore ? (
          <ProjectKindLabel
            kind={
              props.showEngagementScore ? ProjectKind.PROJECT_ENGAGEMENT_SCORE : props.projectKind
            }
            name={props.chartLabel}
            onClick={() => props.onProjectLabelClick(props.projectId, 10)}
          />
        ) : (
          <ProjectKindLabel kind={props.projectKind} />
        )}

        {shouldShowTooltip && (
          <Tooltip
            id="EngagementTooltip"
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={10000}
            title={
              <SummaryInfo
                overrideProjectKind={ProjectSummary.getOverrideProjectKind(
                  props.showEngagementScore
                )}
              />
            }
          >
            <InfoIcon data-testid="engagementInfoTooltip" />
          </Tooltip>
        )}
      </ComponentLabel>
    );
  };

  return (
    <SummaryWidgetsWrapper data-testid="summaryCombineWidgets" isReportView={props.isReportView}>
      <ScrollBar overflow="horizontal" light>
        <SummaryWidgetsContent>
          <ResponseRateWidget
            projectId={props.projectId}
            isReportView={props.isReportView}
            minWidth="auto"
          />

          {props.score && (
            <ComponentWrapper data-testid="kindDependentWidget" minWidth={235}>
              {renderComponentLabel()}

              {props.score && (
                <PercentageBar type={PercentageBarType.Question} value={props.score.value} />
              )}
            </ComponentWrapper>
          )}

          {props.trends && <TrendWidget trends={props.trends} isReportView={props.isReportView} />}

          {Benchmark.isNotNone(props.benchmark) && (
            <BenchmarkWidget
              isUserAdmin={props.isUserAdmin}
              benchmark={props.benchmark}
              isReportView={props.isReportView}
            />
          )}
        </SummaryWidgetsContent>
      </ScrollBar>
    </SummaryWidgetsWrapper>
  );
};

export const SummaryCombineWidgets: React.FC<SummaryCombineWidgetsProps> = (props) => {
  const dispatch = useAppDispatch();

  const trends = useSelector(selectTrends);
  const benchmark = useSelector(selectBenchmark);
  const query = useSelector(selectFiltersQuery);
  const projectId = useSelector(selectCurrentProjectId);
  const isUserAdmin = useSelector(selectIsAdmin);
  const showEngagementScore = useSelector(selectShouldShowEngagementScore);
  const isOverallShow = useSelector(selectIsOverallScoreShow);
  const chartLabel = useSelector(selectChartLabel);
  const projectKind = useSelector(selectProjectKind);
  const score = useSelector(selectScore);

  const onProjectLabelClick = (projectId: number, categoryId: number) => {
    dispatch(
      routeWithQuery({
        pattern: AppRoute.Home.Summary.REPORT,
        params: { id: projectId, categoryId: categoryId },
      })
    );
    dispatch(setEngagementDriverByCategory());
    scrollTop();
  };

  return (
    <SummaryCombineWidgetsPure
      {...props}
      onProjectLabelClick={onProjectLabelClick}
      trends={trends}
      benchmark={benchmark}
      query={query}
      projectId={projectId}
      isUserAdmin={isUserAdmin}
      showEngagementScore={showEngagementScore}
      isOverallShow={isOverallShow}
      chartLabel={chartLabel}
      projectKind={projectKind}
      score={score}
    />
  );
};
