import { AxiosInstance } from 'axios';
import { generateSummaryId } from 'shared/utils/api/generateSummaryId';
import { AISummariesDto, AISummariesModel, mapAISummariesDtoToModel } from 'api/dto/AISummary.dto';

export class AISummaryServiceApi {
  constructor(private client: AxiosInstance) {}

  get({
    pulseTemplateId,
    pulseId,
    departmentId,
  }: {
    pulseTemplateId: number;
    pulseId: number;
    departmentId?: number;
  }): Promise<AISummariesModel> {
    const summaryId = generateSummaryId({ pulseTemplateId, pulseId, departmentId });
    return this.client
      .get<AISummariesDto>(`/api/v1/summary/${summaryId}`)
      .then((response) => mapAISummariesDtoToModel(response.data));
  }
}
