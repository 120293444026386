import { atom } from 'jotai';
import { AIDialogHistoryModel } from 'api/dto/AIDialogHistory.dto';

export const isVariableConfidentialityCompareAtom = atom<boolean>(false);

export const isAISummaryDialogOpenAtom = atom<boolean>(false);

export const isAISummaryDialogExpendedAtom = atom<boolean>(false);

export const aiSummaryDialogHistoryAtom = atom<AIDialogHistoryModel | null>(null);
