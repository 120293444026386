import React, { useEffect, useRef, useState } from 'react';

import { ScaleLinear, ScalePoint } from 'd3-scale';
import { BLACK } from 'app/theme/colors';
import { Benchmark } from 'model/Benchmark';
import { useMediaQuery, useTheme } from '@mui/material';
import { wbFontSize } from '@workbuzz/shared-ui';

export namespace Line {
  export type Scale = {
    xScale: ScalePoint<string>;
    yScale: ScaleLinear<number, number>;
    benchmarkXScale: ScalePoint<string>;
  };

  export type Props = {
    scales: Scale;
    benchmark: Benchmark;
    width: number;
    firstEmptyBenchmarkIndex: string | null;
  };
}

export const BenchmarkLabel: React.FC<Line.Props> = (props) => {
  const benchmarkLabel = `${Benchmark.getShortBenchmarkLabel(props.benchmark)} = ${
    props.benchmark?.value
  }`;

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [positionY, setPositionY] = useState(props.scales.yScale(props.benchmark?.value || 0) - 20);

  const shouldLabelBeInTheMiddle = props.firstEmptyBenchmarkIndex === null;

  const positionX = shouldLabelBeInTheMiddle
    ? props.width / 2 - benchmarkLabel.length
    : props.scales.xScale(props.firstEmptyBenchmarkIndex as string);

  const labelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const label = labelRef.current;
    if (label === null) {
      return;
    }
    const labelRight = label.getBoundingClientRect().right || 0;
    const labelLeft = label.getBoundingClientRect().left || 0;
    const svg = label.closest('svg');
    const chartRight = svg?.getBoundingClientRect().right || 0;
    if (labelRight > chartRight && label.parentElement) {
      label.parentElement.setAttribute('width', (chartRight - labelLeft).toString());
      if (label.clientHeight > 18) {
        setPositionY((p) => p - (label.clientHeight || 0) + 17);
      }
    }
  }, []);

  return (
    <>
      {shouldLabelBeInTheMiddle ? (
        <>
          <defs>
            <filter id="whiteOutlineEffect" colorInterpolationFilters="sRGB">
              <feMorphology in="SourceAlpha" result="MORPH" operator="dilate" radius="2" />
              <feColorMatrix
                in="MORPH"
                result="WHITENED"
                type="matrix"
                values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"
              />
              <feMerge>
                <feMergeNode in="WHITENED" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <g>
            <rect />
            <text
              x={positionX}
              y={props.scales.yScale(props.benchmark?.value || 0) - 7}
              style={{ fontSize: wbFontSize[isDesktop ? 'mdPlus' : 'xsToMd'].body2, fill: BLACK }}
              filter="url(#whiteOutlineEffect)"
              data-testid="benchmarkLineText"
            >
              {benchmarkLabel}
            </text>
          </g>
        </>
      ) : (
        <g>
          <foreignObject x={positionX} y={positionY} width="250" height="300">
            <div
              data-testid="benchmarkLineText"
              ref={labelRef}
              style={{
                fontSize: wbFontSize[isDesktop ? 'mdPlus' : 'xsToMd'].body2,
                fill: BLACK,
              }}
            >
              {benchmarkLabel}
            </div>
          </foreignObject>
        </g>
      )}
    </>
  );
};
