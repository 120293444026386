import React from 'react';
import { useSelector } from 'react-redux';

import { scrollToLastElement } from 'util/scrollToLastElement';
import { getNextAISummaryItemByTitle } from 'util/getNextAISummaryItemByTitle';
import { getAIAvatarItemByTitle } from 'util/getAIAvatarItemByTitle';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { selectAIAvatar, selectAISummary } from 'reduxStore/ai/selectors';
import { selectIsPulseProjectKind, selectProjectLiveSettings } from 'reduxStore/project/selectors';
import {
  selectSelectedDepartmentIds,
  selectSingleSelectedDepartmentId,
} from 'reduxStore/departments/selectors';
import { useDebounceValue } from 'shared/hooks/useDebounceValue';
import { FILTERS_CHANGED_DEBOUNCE_TIMEOUT } from 'shared/constants/timeouts';
import { useAIDialogHistory } from 'shared/hooks/useAIDialogHistory';
import { useIsAISummaryDialogOpenAtom } from 'shared/hooks/useAtoms';
import { useFetchAISummary } from 'shared/hooks/useFetchAISummary';
import { useFetchAIAvatar } from 'shared/hooks/useFetchAIAvatar';
import { WBAITextSummary, WBAITextSummaryProps } from '@workbuzz/shared-ui';
import { Box } from '@mui/material';
import { AISummaryIntroWidget } from 'view/SummaryPage/AISummaryIntroWidget/AISummaryIntroWidget';

export const AITextSummary: React.FC = () => {
  const isPulseProject = useSelector(selectIsPulseProjectKind);
  const isAISummariesEnabledFeature = useFeatureIsOn('is-ai-summaries-enabled');

  const aiSummary = useSelector(selectAISummary);
  const aiAvatar = useSelector(selectAIAvatar);

  const { hasCompanyAiSummariesEnabled, isExecutiveSummaryEnabled, isDepartmentSummaryEnabled } =
    useSelector(selectProjectLiveSettings) || {};

  const selectedDepartmentIdsString = useSelector(selectSelectedDepartmentIds).toString();
  const selectedDepartmentIdsStringDebounced = useDebounceValue(
    selectedDepartmentIdsString,
    FILTERS_CHANGED_DEBOUNCE_TIMEOUT
  );
  const singleTopLevelDepartmentId = useSelector(selectSingleSelectedDepartmentId);
  const singleTopLevelDepartmentIdDebounced = useDebounceValue(
    singleTopLevelDepartmentId,
    FILTERS_CHANGED_DEBOUNCE_TIMEOUT
  );

  const { addHistoryItem } = useAIDialogHistory();
  const [, setIsDialogOpen] = useIsAISummaryDialogOpenAtom();
  useFetchAISummary();
  useFetchAIAvatar();

  const summaries = aiSummary?.sections?.map<WBAITextSummaryProps['summaries']>(
    ({ title, paragraph }) => {
      const aiAvatarItem = getAIAvatarItemByTitle(aiAvatar, title);
      const avatarStatus = aiAvatarItem?.status ?? null;
      const avatarUrl = aiAvatarItem?.url ?? null;
      const nextSectionTitle = getNextAISummaryItemByTitle(aiSummary, title)?.title;

      return {
        title,
        clickHandler: () => {
          if (nextSectionTitle) {
            addHistoryItem({ title, paragraph, avatarStatus, avatarUrl, nextSectionTitle });
            setIsDialogOpen(true);
            scrollToLastElement('.aiDialogQuestion');
          }
        },
      };
    }
  );

  const hasAISummary = Boolean(
    isAISummariesEnabledFeature &&
      isPulseProject &&
      hasCompanyAiSummariesEnabled &&
      summaries &&
      ((isExecutiveSummaryEnabled && !selectedDepartmentIdsStringDebounced) ||
        (isDepartmentSummaryEnabled && singleTopLevelDepartmentIdDebounced))
  );

  if (!hasAISummary) return null;

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3 }}>
      <WBAITextSummary
        summaries={summaries}
        data-testid="aiTextSummary"
        sx={{ width: { md: '50%' } }}
      />
      <AISummaryIntroWidget />
    </Box>
  );
};
