import { createSlice } from '@reduxjs/toolkit';
import { addAsyncCases } from 'shared/utils/redux';
import { Resource } from 'model/Resource';
import { AIAvatarsModel } from 'api/dto/AIAvatar.dto';

import { AIState, STATIC_AVATARS, STATIC_SUMMARIES, STORE_NAME } from './initialState';
import { fetchAIAvatar, fetchAISummary } from './asyncActions';

export const aiSlice = createSlice({
  name: STORE_NAME,
  initialState: AIState.INITIAL_DOMAIN,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAIAvatar.pending, (state) => {
      state.avatar = Resource.createPendingResource<AIAvatarsModel | null>();
    });
    builder.addCase(fetchAIAvatar.fulfilled, (state, action) => {
      state.avatar = Resource.createResolvedResource({ data: action.payload });
      // Add static avatars if departmentId is present
      if (action.meta.arg.departmentId) {
        state.avatar.data?.sections.unshift(...STATIC_AVATARS);
      }
    });
    builder.addCase(fetchAIAvatar.rejected, (state, action) => {
      console.warn('fetchAIAvatar.rejected', action.payload ?? action.error);
      state.avatar = Resource.createResolvedResource({
        data: { id: '', sections: STATIC_AVATARS },
      });
    });

    addAsyncCases(builder, fetchAISummary, (state, action) => {
      state.summary = action.payload;
      // Add static summaries if departmentId is present
      if (action.meta.arg.departmentId) {
        state.summary.data?.sections.unshift(...STATIC_SUMMARIES);
      }
    });
  },
});

export const {} = aiSlice.actions;
