import React from 'react';
import ReactPaginate from 'react-paginate';

import {
  active,
  arrowLinkItem,
  container,
  paginateItem,
  paginateLinkItem,
} from 'component/Pagination/Pagination.s';
import LeftArrow from 'react-icons/lib/md/keyboard-arrow-left';
import RightArrow from 'react-icons/lib/md/keyboard-arrow-right';
import { useMediaQuery, useTheme } from '@mui/material';

export namespace Pagination {
  export type Props = {
    pageCount: number;
    page: number;
    onPageChange: (page: number) => void;
    'data-testid'?: string;
  };
}

export const Pagination: React.FC<Pagination.Props> = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const options = isMobile
    ? {
        pageRangeDisplayed: 2,
        prevLabel: '',
        nextLabel: '',
      }
    : {
        pageRangeDisplayed: 5,
        prevLabel: 'Previous ',
        nextLabel: 'Next ',
      };

  return (
    <div data-testid={props['data-testid']}>
      <ReactPaginate
        previousLabel={
          <span>
            <LeftArrow data-testid="leftArrow" /> {options.prevLabel}
          </span>
        }
        nextLabel={
          <span>
            {options.nextLabel} <RightArrow data-testid="rightArrow" />
          </span>
        }
        pageCount={props.pageCount}
        forcePage={props.page - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={options.pageRangeDisplayed}
        onPageChange={({ selected }) => props.onPageChange(selected + 1)}
        containerClassName={`${container}`}
        pageClassName={`${paginateItem}`}
        pageLinkClassName={`${paginateLinkItem}`}
        previousLinkClassName={`${arrowLinkItem}`}
        nextLinkClassName={`${arrowLinkItem}`}
        activeClassName={`${active}`}
      />
    </div>
  );
};
