import { createAsyncThunk } from '@reduxjs/toolkit';
import { serializeError } from 'shared/utils/redux';
import { AIAvatarsModel } from 'api/dto/AIAvatar.dto';
import { aiAvatarServiceApi } from 'container/aiAvatarServiceApi';
import { aiSummaryServiceApi } from 'container/aiSummaryServiceApi';
import { AISummariesModel } from 'api/dto/AISummary.dto';

import { STORE_NAME } from './initialState';

export const fetchAIAvatar = createAsyncThunk<
  AIAvatarsModel,
  { pulseTemplateId: number; pulseId: number; departmentId?: number }
>(
  `${STORE_NAME}/fetchAIAvatar`,
  ({ pulseTemplateId, pulseId, departmentId }) =>
    aiAvatarServiceApi.get({ pulseTemplateId, pulseId, departmentId }),
  { serializeError }
);

export const fetchAISummary = createAsyncThunk<
  AISummariesModel,
  { pulseTemplateId: number; pulseId: number; departmentId?: number }
>(
  `${STORE_NAME}/fetchAISummary`,
  ({ pulseTemplateId, pulseId, departmentId }) =>
    aiSummaryServiceApi.get({ pulseTemplateId, pulseId, departmentId }),
  { serializeError }
);
