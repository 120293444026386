import { styled } from '@mui/material';
import ArrowRight from 'react-icons/lib/md/keyboard-arrow-right';
import ArrowDown from 'react-icons/lib/md/keyboard-arrow-down';
import { theme } from 'app/theme/theme';

export const DemographyFilterWrapper = styled('ul')({
  padding: 0,
  margin: 0,
});

export const DemographyItem = styled('li')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',

  ':hover': {
    cursor: 'pointer',
  },
});

export const DemographyItemTitle = styled('span')({
  ...theme.typography.body1,
  maxWidth: '218px',
  display: 'inline-block',
});

export const ArrowIconRight = styled(ArrowRight)({
  fontSize: 22,
  cursor: 'pointer',
});

export const ArrowIconDown = styled(ArrowDown)({
  fontSize: 22,
  cursor: 'pointer',
});

export type DemographyChildrenWrapperProps = {
  expanded: boolean;
};

export const DemographyChildrenWrapper = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(
  {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0 10px 10px',
  },
  (props: DemographyChildrenWrapperProps): any => ({
    display: props.expanded ? 'inherit' : 'none',
  })
);
