import { styled } from '@mui/material';
import { CHART_TOOLTIP_BORDER_GREY, WHITE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

export const ChartsContainer = styled('div')({
  display: 'flex',
  gap: '16px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
});

export const ChartWrapper = styled('div')({
  background: WHITE,
  borderRadius: '10px',
  padding: '16px',
  flex: '1 1 0',
  width: 0,

  '.recommendChartInfoIcon': {
    margin: 0,
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
});

export const ChartTooltipWrapper = styled('div')({
  ...theme.typography.subtitle2,
  margin: '0px',
  padding: '10px',
  backgroundColor: WHITE,
  border: `1px solid ${CHART_TOOLTIP_BORDER_GREY}`,
});
