import { styled } from '@mui/material';
import { IRON, MARINER, WHITE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

type ButtonProps = {
  disabled: boolean;
};

export const WBOutlinedButton = styled('button')<ButtonProps>(
  {
    ...theme.typography.subtitle1,
    fontWeight: 800,
    backgroundColor: WHITE,
    cursor: 'pointer',
    border: `2px solid ${IRON}`,
    borderRadius: '6px',
    color: MARINER,
    padding: '8px 32px',
    marginTop: 30,
  },
  (props: ButtonProps) => ({
    cursor: props.disabled ? 'wait' : 'pointer',
  })
);
