import { styled } from '@mui/material';
import { Button } from 'component/NavButton/NavButton.s';
import { ASH_IN_THE_AIR, IRON, MARINER, WHITE } from 'app/theme/colors';
import { theme } from 'app/theme/theme';

const BaseViewAllButton = styled(Button)({
  ...theme.typography.subtitle1,
  backgroundColor: WHITE,
  border: `2px solid ${IRON}`,
  padding: '8px 12px',
  height: 'auto',
  borderRadius: '6px',
  color: MARINER,
  ':active': {
    backgroundColor: ASH_IN_THE_AIR,
  },
});

export const DesktopViewAllButton = styled(BaseViewAllButton)({});

export const MobileViewAllButton = styled(BaseViewAllButton)({
  marginTop: '16px',
});
